import React from 'react';
import { Paper } from '@mui/material';

import "./style.scss";

export const DayViewSection = ({title, className, children}) => {
  return (
    <Paper elevation={3} square={true} className={`day-view-section-card ${className}`}>
      <div className="day-view-section-card-header">
        <span>{title}</span>
      </div>
      <div className="day-view-section-card-body">
        {children}
      </div>
    </Paper>
  );
};

export default DayViewSection;
export const hoursComparator = (v1, v2) => {
  // Format first value to be just the number of hours
  const removeLetter = v1.slice(0, v1.indexOf("h"));

  const onlyNumbers = removeLetter.replace(/,/g, "");
  const convertToNum = parseInt(onlyNumbers);
  // Format second value in the same way # Should create a function to do this #
  const removeOtherLetter = v2.slice(0, v2.indexOf("h"));

  const onlyOtherNumbers = removeOtherLetter.replace(/,/g, "");
  const convertToOtherNum = parseInt(onlyOtherNumbers);

  if (convertToNum == convertToOtherNum) {
    const testing = v1.slice(v1.indexOf("h") + 1);
    const justNum = testing.replace(/[m]/g, "");
    const makeNum = parseInt(justNum);

    const testingOther = v2.slice(v2.indexOf("h") + 1);
    const justNumOther = testingOther.replace(/[m]/g, "");
    const makeNumOther = parseInt(justNumOther);

    if (makeNum >= makeNumOther) {
      return 1;
    } else {
      return -1;
    }
  }

  return convertToNum - convertToOtherNum;
};

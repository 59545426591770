import React from 'react';
import { Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import "./style.scss";

export const SectionCard = ({title, loadingData, children}) => {
  return (
    <Paper elevation={3} square={true} className="section-card">
      {
        title && <div className="section-card-header">
          <span>{title}</span>
          {
            loadingData && <span><CircularProgress color="inherit" size={20}/></span>
          }
        </div>
      }
      <div className="section-card-body">
        {children}
      </div>
    </Paper>
  );
};

export default SectionCard;
require('isomorphic-fetch');

export function getRequest({ url, params }) {
  return fetch(`${url}${parsedParams(params)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": getCsrfToken(),
    },
  });
}

const parsedParams = (params) => {
  if (params) {
    return (
      "?" +
      Object.keys(params)
        .map((key) => encodeParamAsQueryString(key, params[key]))
        .join("&")
    );
  } else {
    return "";
  }
};

const encodeParamAsQueryString = (key, value) => {
  if (Array.isArray(value) === true) {
    return value
      .map((innerValue) => `${encodeURIComponent(key)}[]=${innerValue}`)
      .join("&");
  } else {
    return `${encodeURIComponent(key)}=${value}`;
  }
};

export const getCsrfToken = () => {
  const metaCsrf = document.querySelector("meta[name='csrf-token']");
  return metaCsrf ? metaCsrf.getAttribute("content") : "testenv";
};
import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";

export const Staffing = ({ taxonomy, staffingLevels }) => {
  const titleTaxonomy = taxonomy.acuity_score;
  const peopleTaxonomy = taxonomy.people;

  return (
  	<div className="module staffing">
        <h4>{peopleTaxonomy} Levels</h4>
          {staffingLevels && <> 
            <div className="stat-wrap">
              <div className="stat">
                <span>Total {peopleTaxonomy} Available</span>
                <p>{staffingLevels.total_staff}</p>
              </div>
            </div>

            <ul className="acuity-list">
              <li>
                <span>Bands</span>
                <span>Quantity</span>
              </li>
              {staffingLevels.data.map((value, index) => {
                const bandClass = (value.band).replace(/\s+/g, '-').toLowerCase();
                return <li>
                  <div>
                    <span className={'level-' + bandClass}></span>
                    <span>{value.band}</span>
                  </div>
                  <span>{value.qty}</span>
                </li>
              })}
            </ul>
        </>}
    </div>
  );
};

export default Staffing;
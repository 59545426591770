import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import { getRequest } from "../services/base_requests";
import DurationOfVisits from "../components/acuity_historic/duration_of_visits/DurationOfVisits";
import FilterForm from "../components/acuity_historic/filter_form/FilterForm";
import SummaryCards from "../components/acuity_historic/summary_cards/SummaryCards";
import StaffingLevelsSection from "../components/acuity_historic/staffing_levels/StaffingLevelsSection.js";
import VisitsByLevelBarChart from "../components/acuity_historic/visits_by_level_bar_chart/VisitsByLevelBarChart";
import VisitsByPostCode from "../components/acuity_historic/visits_by_postcode/VisitsByPostCode";

import "./style.scss";
import 'mapbox-gl/dist/mapbox-gl.css';

import {
  sub,
  endOfWeek,
  startOfWeek,
} from "date-fns";

const dateNow = new Date();
const startDate = startOfWeek(sub(dateNow, { weeks: 1 }), { weekStartsOn: 1 });
const endDate = endOfWeek(sub(dateNow, { weeks: 1 }), { weekStartsOn: 1 });

export const AcuityHistoricPage = ({ groups, taxonomy, peopleTaxonomy }) => {
  const [isOpenFrom, setIsOpenFrom] = useState(false);
  const [isOpenTo, setIsOpenTo] = useState(false);
  const [dateFrom, setDateFrom] = useState(startDate);
  const [dateTo, setDateTo] = useState(endDate);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);
  const titleTaxonomy = taxonomy.acuity_score;
  const [loadingData, setLoadingData] = useState(false);
  const [acuityData, setAcuityData] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [staffingLevelsData, setStaffingLevelsData] = useState(undefined)
  const groupsForSelect = [ { value: "", label: "All groups" },
                            ...groups.map((group) => { return { value: group.id, label: group.name }; })
                          ];
  const [visitsByLevelData, setVisitsByLevelData] = useState(undefined);
  const [durationOfVisitsData, setDurationOfVisitsData] = useState(undefined);
  const [summaryCards, setSummaryCards] = useState(undefined);
  const [acuityScores, setAcuityScores] = useState(undefined)
  const [visitsByPostCodeData, setVisitsByPostCodeData] = useState(undefined);

  const getAllData = () => {
    setLoadingData(true);
    const selectedGroupsValue = selectedGroups.map(group => group.value);

    Promise.all([
      getRequest({
        url: "/api/dashboard/v1/acuity-historic",
        params: {
          groups: selectedGroupsValue.join(),
          dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
          dateTo: moment(dateTo).format('YYYY-MM-DD'),
        },
      }),
    ])
      .then(async (responses) => {
        setLoadingData(false);
        const acuityHistoricResponse = responses[0];

        const acuityData = await acuityHistoricResponse.json();
        setAcuityData(acuityData)

        setSummaryCards(acuityData.summary_cards);
        setDurationOfVisitsData({labels: acuityData.events_line_chart.labels, datasets: formatDurationData(acuityData.events_line_chart.datasets)})
        setVisitsByLevelData(acuityData.events_by_level_chart);
        setVisitsByPostCodeData(acuityData.visits_map);
        setAcuityScores(acuityData.acuity_scores)
      })
      .catch((ex) => {});
  };

  const getStaffingLevelsData = (acuity_level = 'all') => {
    setLoadingData(true);
    const selectedGroupsValue = selectedGroups.map(group => group.value);

    Promise.all([
      getRequest({
        url: "/api/dashboard/v1/acuity-historic/staffing_levels",
        params: {
          groups: selectedGroupsValue.join(),
          dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
          dateTo: moment(dateTo).format('YYYY-MM-DD'),
          acuity_level: acuity_level,
        },
      }),
    ])
      .then(async (responses) => {
        setLoadingData(false);
        const staffingResponse = responses[0];

        const staffingData = await staffingResponse.json();
        
        setStaffingLevelsData(staffingData.staffing_levels);
      })
      .catch((ex) => {});
  };

  const formatDurationData = (data) => {
    return data.map((dataset) => (
      {...dataset, data: dataset.data.map(value => parseFloat((value/60).toFixed(2)))}
    ))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loadingData) return;

    getAllData();
    getStaffingLevelsData();
  };

  const handleLevelChange = (acuity_level) => {
    if (loadingData) return;
    getStaffingLevelsData(acuity_level);
  }

  return (
    <div className="page-container">
      <div className="page-title-container">
        <Container maxWidth="xl">
          <h1>{titleTaxonomy}</h1>
        </Container>
      </div>
      <Container maxWidth="xl">
        <h3>{titleTaxonomy} Trends</h3>
      </Container>
      <FilterForm 
        groupsForSelect={groupsForSelect} 
        loadingData={loadingData} 
        selectedGroups={selectedGroups} 
        setSelectedGroups={setSelectedGroups} 
        handleSubmit={handleSubmit}
        isOpenFrom={isOpenFrom}
        setIsOpenFrom={setIsOpenFrom}
        isOpenTo={isOpenTo}
        setIsOpenTo={setIsOpenTo}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
        setDateFrom={setDateFrom}
        selectedTimePeriod={selectedTimePeriod}
        setSelectedTimePeriod={setSelectedTimePeriod}
      />
      { staffingLevelsData && acuityData && (
        <>
        <SummaryCards taxonomy={taxonomy} summaryCards={summaryCards} acuityScores={acuityScores} />
        <StaffingLevelsSection staffingLevelsData={staffingLevelsData} taxonomy={taxonomy} peopleTaxonomy={peopleTaxonomy} handleLevelChange={handleLevelChange} acuityScores={acuityScores}/>
        <VisitsByLevelBarChart taxonomy={taxonomy} visitsByLevelData={visitsByLevelData} />
        <DurationOfVisits durationOfVisitsData={durationOfVisitsData} loadingData={loadingData} taxonomy={taxonomy}/>
        <VisitsByPostCode taxonomy={taxonomy} visitsByPostCodeData={visitsByPostCodeData} acuityScores={acuityScores} />
        </>
      )}
    </div>
  );
};

export default AcuityHistoricPage;

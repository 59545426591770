import React, { useEffect, useState } from "react";
import { FormGroup, FormControlLabel, Switch, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DatePicker } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  sub,
  add,
  differenceInCalendarMonths,
  differenceInDays,
  endOfMonth,
  startOfMonth,
  isAfter,
} from "date-fns";
import SectionCard from "../section_card/SectionCard";

import "./style.scss";
import TrendsBarChart from "../trends_bar_chart/TrendsBarChart";

export const TrendsSection = ({
  trends,
  trendsCompare,
  loadingData,
  dateTo,
  dateFrom,
  dateToCompare,
  dateFromCompare,
  viewComparisonMode,
  updateViewMode,
  setDateTo,
  setDateFrom,
  setDateToCompare,
  setDateFromCompare,
  getAllData,
  setSelectedTimePeriod,
  selectedTimePeriod,
  dateRangeReminder,
  setDateRangeReminder,
  isOpenCurrentFrom,
  setIsOpenCurrentFrom,
  isOpenCurrentTo,
  setIsOpenCurrentTo,
  isOpenCompareFrom,
  setIsOpenCompareFrom,
  selectedDisplayBy,
  setSelectedDisplayBy,
  isDisabled,
  setIsDisabled,
  datePickerView, 
  setDatePickerView,
  datePickerOpenTo,
  setDatePickerOpenTo,
  isNotMondayWhenDisplayedByDay,
  currentPeriodLength
}) => {
  // Setting the maximum date that the comparison date can start from
  const maxDateFromCompare = () => {
    if (selectedDisplayBy == 'Week') {
      return sub(dateFrom, { days: currentPeriodLength() });
    } else {
      return startOfMonth(sub(dateFrom, { months: currentPeriodLength() }));;
    }
  }

  // When the first date from datepicker is changed
  const handleCurrentFrom = (newValue) => {
    setDateFrom(newValue);
    setSelectedTimePeriod(null);
    setDateRangeReminder("");

    if (selectedDisplayBy == 'Week') {
      setDateTo(add(newValue, { days: 6 }));
      setDateFromCompare(sub(newValue, { days: currentPeriodLength() }))
      setDateToCompare(sub(newValue, { days: 1 }))
    } else {
      const maxValueSub = endOfMonth(add(newValue, { months: 11 }));

      if (isAfter(newValue, dateTo)) {
        setDateTo(add(newValue, { months: 1 }));
        setSelectedTimePeriod(null);
        setDateRangeReminder("End date must be after start date");
      } else {
        setDateFromCompare(
          startOfMonth(sub(dateToCompare, { months: currentPeriodLength() - 1 }))
        );
      }

      if (isAfter(dateTo, maxValueSub)) {
        setDateTo(maxValueSub);
        setDateRangeReminder("Limited to 12 months");
        setDateFromCompare(startOfMonth(sub(newValue, { months: 12 })));
        setDateToCompare(endOfMonth(sub(maxValueSub, { months: 12 })));
      } else {
        setDateFromCompare(
          startOfMonth(sub(dateToCompare, { months: currentPeriodLength() - 1 }))
        );
      }
    }
  };

  // When the first date to datepicker is changed
  const handleCurrentTo = (newValue) => {
    setDateTo(newValue);
    setSelectedTimePeriod(null);
    setDateRangeReminder("");
    setDateToCompare(
      endOfMonth(add(dateFromCompare, { months: currentPeriodLength() - 1 }))
    );
  };

  // When the date from comparison datepicker is changed
  const handleCompareFrom = (newValue) => {
    setDateFromCompare(newValue);
    if(selectedDisplayBy == 'Week') {
      setDateToCompare(add(newValue, { days: 6 }))
    } else {
      setDateToCompare(endOfMonth(add(newValue, { months: currentPeriodLength() - 1 })));
    }
  };

  // When the date To comparison datepicker is changed
  const handleCompareTo = () => {
    setDateToCompare(newValue);
  };

  return (
    <SectionCard title="Capacity vs Demand Trends" loadingData={loadingData}>
      {trends && (
        <>
          <TrendsBarChart
            trends={trends}
            trendsCompare={trendsCompare}
            viewComparisonMode={viewComparisonMode}
          />
          <div className="comparison-wrapper">
            <FormGroup>
              <FormControlLabel
                control={<Switch onChange={() => updateViewMode()} />}
                label="Compare previous period"
              />
            </FormGroup>

            <LocalizationProvider dateAdapter={DateAdapter}>
              {viewComparisonMode && (
                <div className="comparison-form">
                  <div className="comparison-date-pickers">
                    <div className="date-picker-group">
                      <p>Current period</p>
                      <span>
                        <DatePicker
                          open={isOpenCurrentFrom}
                          onOpen={() => setIsOpenCurrentFrom(true)}
                          onClose={() => { 
                              setIsOpenCurrentFrom(false)
                              if(selectedDisplayBy == 'Week') {
                                setDateFromCompare(sub(dateFrom, { days: currentPeriodLength() }))
                                setDateToCompare(sub(dateFrom, { days: 1 }))
                              }
                            }
                          }
                          inputFormat="dd/MM/yyyy"
                          openTo={datePickerOpenTo}
                          views={datePickerView}
                          label="Start date"
                          maxDate={dateTo}
                          value={dateFrom}
                          disabled={loadingData}
                          shouldDisableDate={isNotMondayWhenDisplayedByDay}
                          onChange={(newValue) => {
                            handleCurrentFrom(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              {...params}
                              onClick={(e) => setIsOpenCurrentFrom(true)}
                              size="normal"
                            />
                          )}
                        />
                        <DatePicker
                          open={isOpenCurrentTo}
                          onOpen={() => setIsOpenCurrentTo(true)}
                          onClose={() => { 
                              setIsOpenCurrentTo(false)
                              if(selectedDisplayBy == 'Week') {
                                setDateFromCompare(sub(dateFrom, { days: 7 }))
                                setDateToCompare(sub(dateFrom, { days: 1 }))
                              }
                            }
                          }
                          inputFormat="dd/MM/yyyy"
                          openTo={datePickerOpenTo}
                          views={datePickerView}
                          label="End date"
                          maxDate={dateTo}
                          value={dateTo}
                          disabled={isDisabled}
                          shouldDisableDate={isNotMondayWhenDisplayedByDay}
                          onChange={(newValue) => {
                            handleCurrentTo(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              {...params}
                              onClick={(e) => setIsOpenCurrentTo(true)}
                              helperText={dateRangeReminder}
                              size="normal"
                            />
                          )}
                        />
                      </span>
                    </div>

                    <div className="date-picker-group">
                      <p>Previous period</p>
                      <span>
                        <DatePicker
                          open={isOpenCompareFrom}
                          onOpen={() => setIsOpenCompareFrom(true)}
                          onClose={() => setIsOpenCompareFrom(false)}
                          inputFormat="dd/MM/yyyy"
                          openTo={datePickerOpenTo}
                          views={datePickerView}
                          label="Start date"
                          maxDate={maxDateFromCompare()}
                          value={dateFromCompare}
                          disabled={loadingData}
                          shouldDisableDate={isNotMondayWhenDisplayedByDay}
                          onChange={(newValue) => {
                            handleCompareFrom(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              {...params}
                              onClick={(e) => setIsOpenCompareFrom(true)}
                              size="normal"
                            />
                          )}
                        />
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          openTo={datePickerOpenTo}
                          views={datePickerView}
                          label="End date"
                          value={dateToCompare}
                          disabled={isDisabled}
                          shouldDisableDate={isNotMondayWhenDisplayedByDay}
                          readOnly
                          onChange={(newValue) => {
                            handleCompareTo(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              {...params}
                              size="normal"
                            />
                          )}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="comparison-form-actions">
                    <LoadingButton
                      loading={loadingData}
                      variant="contained"
                      size="small"
                      onClick={() => getAllData()}
                      className="get-comparison-data-button"
                    >
                      Compare
                    </LoadingButton>
                  </div>
                </div>
              )}
            </LocalizationProvider>
          </div>
        </>
      )}
    </SectionCard>
  );
};

export default TrendsSection;

import React, { useState } from "react";
import { Container } from "@mui/material";

import { getRequest } from "../services/base_requests";

import "./style.scss";
import SummaryCards from "../components/acuity_today/summary_cards/SummaryCards";
import Visits from "../components/acuity_today/visits/Visits";
import Staffing from "../components/acuity_today/staffing/Staffing";
import ThisWeekBarCharts from "../components/acuity_today/this_week_bar_charts/ThisWeekBarCharts";
import FilterForm from "../components/acuity_today/filter_form/FilterForm";

export const AcuityTodayPage = ({ groups, taxonomy }) => {
  const titleTaxonomy = taxonomy.acuity_score;
  const today = String(new Date()).slice(0, 16);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [acuityData, setAcuityData] = useState(undefined);
  const [staffingLevels, setStaffingLevels] = useState(undefined);
  const [todaysVisits, setTodaysVisits] = useState(undefined);
  const [acuityScores, setAcuityScores] = useState(undefined);
  const [thisWeekData, setThisWeekData] = useState(undefined);
  const groupsForSelect = [
    { value: "", label: "All groups" },
    ...groups.map((group) => ({ value: group.id, label: group.name })),
  ];

  const getAllData = () => {
    setLoadingData(true);
    const selectedGroupsValue = selectedGroups.map((group) => group.value);

    getRequest({
      url: "/api/dashboard/v1/acuity-today",
      params: {
        groups: selectedGroupsValue.join(),
      },
    })
      .then(async (response) => {
        setLoadingData(false);
        const acuityData = await response.json();
        setAcuityData(acuityData)
        setStaffingLevels(acuityData.staffing_levels);
        setTodaysVisits(acuityData.todays_visits);
        setThisWeekData(acuityData.this_week_chart_data);
        setAcuityScores(acuityData.acuity_scores);
      })
      .catch((ex) => {
        setLoadingData(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!loadingData) {
      getAllData();
    }
  };

  return (
    <div className="page-container">
      <div className="page-title-container">
        <Container maxWidth="xl">
          <h1>{titleTaxonomy}</h1>
        </Container>
      </div>
      <Container maxWidth="xl">
        <h3>Today - {today}</h3>
      </Container>
      <FilterForm
        groupsForSelect={groupsForSelect}
        loadingData={loadingData}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
        handleSubmit={handleSubmit}
      />
      <SummaryCards
        taxonomy={taxonomy}
        todaysVisits={todaysVisits}
        acuityScores={acuityScores}
        loadingData={loadingData}
      />
      {acuityData && (
        <>
        <Container maxWidth="xl" className="module-wrap">
          <Visits
            taxonomy={taxonomy}
            todaysVisits={todaysVisits}
            loadingData={loadingData}
          />
          <Staffing
            taxonomy={taxonomy}
            staffingLevels={staffingLevels}
            loadingData={loadingData}
          />
        </Container>
        <ThisWeekBarCharts
          taxonomy={taxonomy}
          thisWeekData={thisWeekData}
          loadingData={loadingData}
          today={today}
        />
        </>
      )}
    </div>
  );
};

export default AcuityTodayPage;

import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Bar } from "react-chartjs-2";
import { Container } from "@mui/material";
import BlueInfoIcon from '../../../../assets/images/icons/icon-info-blue.svg';
import BarChartExplained from '../../../../assets/images/bar_chart_explained.jpg';

import "./style.scss";

export const ThisWeekBarCharts = ({ taxonomy, thisWeekData, today }) => {
  const titleTaxonomy = taxonomy.acuity_score;
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);
  const availableViews = ["bands_levels", "bands", "levels"]
  const [selectedView, setSelectedView] = useState("bands_levels");
  const availableTitles = ["Staff Bands / Levels", "Staff Bands", "Levels"]
  const [legendTitle, setLegendTitle] = useState("Staff Bands / Levels");

  const getChartDataForSelectedView = () => {
    let datasets = [];
    if (selectedView == "bands_levels"){
      datasets = [...thisWeekData.datasetsBands, ...thisWeekData.datasetsLevels];
    } else if (selectedView == "bands"){
      datasets = [...thisWeekData.datasetsBands];
    } else if (selectedView == "levels") {
      datasets = [...thisWeekData.datasetsLevels];
    }
    return {
      labels: thisWeekData.labels,
      datasets: datasets,
      chartModals: thisWeekData.chart_modals_data
    }
  }

  const updateSelectedView =(event, newValue) => {
    setSelectedView(availableViews[newValue]);
    setLegendTitle(availableTitles[newValue]);
  }

  const CHART_OPTIONS_BAR = {
    maintainAspectRatio: false,
    categoryPercentage: 0.6,
    barPercentage: 0.7,
    animation: {
      duration: 1000,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Hours",
          color: "#666",
          align: "center",
          font: {
            size: 16,
            weight: "normal",
            lineHeight: 1.2,
          },
          padding: {top: 0, left: 0, right: 0, bottom: 15}
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 15,
          padding: 10,
          align: 'start',
        },
        title: {
          display: true,
          text: legendTitle,
          padding: {
            top: 10,
          },
        },
      },
      tooltip: {
        mode: 'index',
        position: "average",
        filter: function (data) {
          if (data.formattedValue == '0') {
              return false;
          } else {
              return true;
          }
        },
        callbacks: {
          label: (context) => {
            const tooltipDataLabel = context.dataset.label;
            return `${tooltipDataLabel}: ${context.raw} hrs`;
          },
        },
      }
    }
  };

  return (
    <Container maxWidth="xl">
      <h3>This Week</h3>
      {
          thisWeekData && <div className="module this-week">
        <h4>Week View - commencing {today} <a className="btn btn-small btn-outline btn-popup" data-toggle="modal" href="#bar_chart_modal"><img className="margin_right_5" src={BlueInfoIcon} />Stacked Bar Charts</a></h4>
        <div className="this-week-bar-charts">
            <Tabs value={availableViews.indexOf(selectedView)} onChange={updateSelectedView} aria-label="basic tabs example">
              <Tab label="Show Bands & Levels" index={0}/>
              <Tab label="Show Bands Only" index={1}/>
              <Tab label="Show Levels Only" index={2}/>
            </Tabs>
            <Bar options={CHART_OPTIONS_BAR} data={getChartDataForSelectedView()} />
          </div>
      </div>
      }
      <div className="modal hide" id="bar_chart_modal">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal">×</button>
          <h3>Stacked Bar Charts Explained</h3>
        </div>
        <div className="modal-body">
            <p>This section gives an overview of the current week, showing Capacity and Demand against Band of staff and the {titleTaxonomy}. This can be further broken down by clicking on the tabs “Show Bands Only” and “Show Levels Only”. This allows an oversight of different bands of staff and what patients they are visiting with different {titleTaxonomy} i.e. higher band of staff should ideally be seeing patients who have a identified high level of care.</p>
            <img src={BarChartExplained} />
        </div>
        <div className="modal-footer">
          <a href="#" className="btn" data-dismiss="modal">Close</a>
        </div>
      </div>
    </Container>
  );
}

export default ThisWeekBarCharts;
import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Paper } from '@mui/material';
import { Bar } from "react-chartjs-2";
import { Container } from "@mui/material";
import BlueInfoIcon from '../../../../assets/images/icons/icon-info-blue.svg';
import BarChartExplained from '../../../../assets/images/number_of_visits_by_level_explained.jpg';

import "./style.scss";

export const VisitsByLevelBarChart = ({ taxonomy, visitsByLevelData }) => {
  const visitTaxonomy = taxonomy.event;
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

  const CHART_OPTIONS_BAR = {
    maintainAspectRatio: false,
    categoryPercentage: 0.6,
    barPercentage: 0.7,
    animation: {
      duration: 1000,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: visitTaxonomy,
          color: "#666",
          align: "center",
          font: {
            size: 16,
            weight: "normal",
            lineHeight: 1.2,
          },
          padding: {top: 0, left: 0, right: 0, bottom: 15}
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 15,
          padding: 10,
          align: 'start',
        },
      },
      tooltip: {
        mode: 'index',
        position: "nearest",
        intersect: false,
      }
    }
  };


  return (
    <Container maxWidth="xl">
      {
          visitsByLevelData && <div className="module visits">
        <Paper elevation={3} square={true}>
        <h4>Number of {visitTaxonomy} by Level <a className="btn btn-small btn-outline btn-popup" data-toggle="modal" href="#bar_chart_modal"><img className="margin_right_5" src={BlueInfoIcon} />Bar Charts</a></h4>
        <div className="visits-bar-chart">
            <Bar options={CHART_OPTIONS_BAR} data={visitsByLevelData} />
          </div>
          </Paper>
      </div>
      }
      <div className="modal hide" id="bar_chart_modal">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal">×</button>
          <h3>Bar Chart Explained</h3>
        </div>
        <div className="modal-body">
            <img src={BarChartExplained} />
        </div>
        <div className="modal-footer">
          <a href="#" className="btn" data-dismiss="modal">Close</a>
        </div>
      </div>
    </Container>
  );
}

export default VisitsByLevelBarChart;

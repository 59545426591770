import React from "react";
import Select from "react-select";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DatePicker } from "@mui/lab";
import { add } from "date-fns";
import SectionCard from "../../demand_capacity/section_card/SectionCard";

import "./style.scss";

const dateNow = new Date();

export const FilterForm = ({
  datePickerOpenTo = "month",
  datePickerViews = ["month", "year"],
  hasPeriodHelpers = true,
  calculatesEndDate = false,
  handleSubmit,
  dateFrom,
  dateTo,
  groupsForSelect,
  loadingData,
  setDateFrom,
  setDateTo,
  setSelectedGroups,
  selectedGroups,
  disableBtn,
  setDisableBtn,
  dateRangeReminder,
  isOpenFrom,
  setIsOpenFrom,
  setIsOpenTo,
}) => {

  // When datepickers are changed
  const handleOnChangeFrom = (newValue) => {
    setDateFrom(newValue);
    setDateTo(add(newValue, { days: 6 }));
  };

  const handleOnChangeTo = (newValue) => {
    setDateTo(newValue);
  };

  // Handle Group Change
  const handleGroupChange = (selection) => {
    const numberOfSelectedGroups = selection.length;

    if(numberOfSelectedGroups > 1){
      if(selection[numberOfSelectedGroups - 1].value === "") {
        selection.splice(0, numberOfSelectedGroups - 1);
      } else if (selection[0].value === "") {
        selection.shift();
      }
    }

    setSelectedGroups(selection);

    if (selection.length === 0) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  return (
    <SectionCard>
      <form onSubmit={(e) => handleSubmit(e)} className="filter-form">
        <LocalizationProvider dateAdapter={DateAdapter}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className="date-pickers">
                <DatePicker
                  open={isOpenFrom}
                  onOpen={() => setIsOpenFrom(true)}
                  onClose={() => setIsOpenFrom(false)}
                  inputFormat="dd/MM/yyyy"
                  openTo={datePickerOpenTo}
                  views={datePickerViews}
                  label="Start date"
                  minDate={dateNow}
                  value={dateFrom}
                  disabled={loadingData}
                  onChange={(newValue) => {
                    handleOnChangeFrom(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="helper-text"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      onClick={(e) => setIsOpenFrom(true)}
                      {...params}
                      size="normal"
                    />
                  )}
                />
                <DatePicker
                  open={false}
                  inputFormat="dd/MM/yyyy"
                  openTo={datePickerOpenTo}
                  views={datePickerViews}
                  label="End date"
                  value={dateTo}
                  disabled={loadingData}
                  readOnly={calculatesEndDate}
                  onChange={(newValue) => {
                    handleOnChangeTo(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      {...params}
                      onClick={(e) => setIsOpenTo(true)}
                      helperText={dateRangeReminder}
                      size="normal"
                    />
                  )}
                />
              </div>
            </Grid>
            {hasPeriodHelpers && (
              <>
                <Grid item xs={0.3}>
                  <p className="or-select-text">Or</p>
                </Grid>
              </>
            )}
            <Grid item xs={3}>
              <Select
                options={groupsForSelect}
                defaultValue={selectedGroups}
                openMenuOnFocus={true}
                isMulti={true}
                isDisabled={loadingData}
                closeMenuOnSelect={false}
                placeholder="Select Group"
                onChange={(selection) => handleGroupChange(selection)}
              />
            </Grid>
            <Grid item xs={2}>
              <div className="text-left mt-2">
                <LoadingButton
                  disabled={disableBtn}
                  className="apply-filters-btn"
                  loading={loadingData}
                  variant="contained"
                  type="submit"
                  size="small"
                >
                  Apply filters
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </form>
    </SectionCard>
  );
};

export default FilterForm;

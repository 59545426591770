import React, { useState } from "react";
import Select from "react-select";
import { Container, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import SectionCard from "../../demand_capacity/section_card/SectionCard";

export const FilterForm = ({groupsForSelect, selectedGroups, loadingData, setSelectedGroups, handleSubmit}) => {
  const [disableBtn, setDisableBtn] = useState(true);

  // Handle Group Change
  const handleGroupChange = (selection) => {
    const numberOfSelectedGroups = selection.length;

    if(numberOfSelectedGroups > 1){
      if(selection[numberOfSelectedGroups - 1].value === "") {
        selection.splice(0, numberOfSelectedGroups - 1);
      } else if (selection[0].value === "") {
        selection.shift();
      }
    }

    setSelectedGroups(selection);

    if (selection.length === 0) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  return (
    <Container maxWidth="xl">
      <form onSubmit={(e) => handleSubmit(e)} className="filter-form">
        <SectionCard>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Select
                options={groupsForSelect}
                defaultValue={selectedGroups}
                openMenuOnFocus={true}
                isMulti={true}
                isDisabled={loadingData}
                closeMenuOnSelect={false}
                placeholder="Select Group"
                onChange={(selection) => handleGroupChange(selection)}
              />
            </Grid>
            <Grid item xs={2}>
              <div className="text-left mt-2">
                <LoadingButton
                  disabled={disableBtn} 
                  className="apply-filters-btn"
                  loading={loadingData}
                  variant="contained"
                  type="submit"
                >
                  Apply filters
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </SectionCard>
      </form>
    </Container>
  );
}

export default FilterForm;
import React from "react";
import SectionCard from "../section_card/SectionCard";
import SectionDataGrid from "../section_data_grid/SectionDataGrid";

import "./style.scss";

export const InterventionDemandSection = ({
  interventionDemand,
  loadingData,
}) => {
  const columns = [
    {
      field: "title",
      headerName: "Intervention",
      flex: 1,
    },
    {
      field: "qty",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "percentage",
      headerName: "Percentage",
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
  ];

  let rows = [];

  if (interventionDemand) {
    rows = interventionDemand.data.map((entry, index) => {
      entry["id"] = index;
      return entry;
    });
  }

  return (
    <SectionCard title="Intervention Demand" loadingData={loadingData}>
      {interventionDemand && (
        <div>
          <div className="section-totals">
            <h3>
              <span className="total-number">
                {interventionDemand.totalInterventions}
              </span>{" "}
              Total Interventions
            </h3>
            <h3>
              <span className="total-number">
                {interventionDemand.totalVisits}
              </span>{" "}
              Total Visits
            </h3>
          </div>
          <SectionDataGrid rows={rows} columns={columns} />
        </div>
      )}
    </SectionCard>
  );
};

export default InterventionDemandSection;

import React from "react";
import DayViewSection from "../day_view_section/DayViewSection";
import Grid from "@mui/material/Grid";
import { format } from "date-fns";
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import WarningIcon from '@mui/icons-material/Warning';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';

import "./style.scss";

const secondsToHoursMins = (seconds) => {
  if (seconds === 0) return 0;

  let totalSeconds = seconds;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);

  if (minutes === 0) {
    return `${hours}h`;
  } else {
    return `${hours}h ${minutes}m`;
  }
};

export const CurrentDayView = ({ dayData }) => {
  const capacityData = dayData.capacity;
  const demandData = dayData.demand;
  const contactTimeComplianceData = dayData.contactCompliance;
  const caseloadData = dayData.caseload;
  const visitOutcomesData = dayData.visitOutcomes;

  function exceededCapacityWarningStyle(){
    let warningClass = "show-warning"

    if (contactTimeComplianceData.staffExceededContactTime.value === 0) {
      warningClass = "no-warning"
    };

    return warningClass;
  };

  function checkOutWarningStyle(){
    let warningClass = "show-warning"

    if (contactTimeComplianceData.checkOut >= 95) {
      warningClass = "no-warning"
    };

    return warningClass;
  };

  function demandWarningStyle(){
    let warningClass = "show-warning"

    if (demandData.unallocated.value === 0) {
      warningClass = "no-warning"
    };

    return warningClass;
  };

  return (
    <div className="current-day-view-wrapper">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {demandData && (
          <Grid item xs={6}>
            <DayViewSection className="demand-display" title="Demand">
              <Grid container>
                <Grid item xs={6} className="border-right">
                  <h4>Total Visits</h4>
                  <p className="large">{demandData.totalVisits.value}</p>
                  <div className="flex">
                    <p className="icon-wrap purple blue-text"><WatchLaterIcon fontSize="small" />{secondsToHoursMins(demandData.totalVisits.time)}</p>
                    <p className="icon-wrap purple blue-text"><DirectionsCarIcon fontSize="small" />{secondsToHoursMins(demandData.totalVisits.car)}</p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                <WarningIcon fontSize="large" color="warning" className={demandWarningStyle()} />
                  <h4>Unallocated</h4>
                  <p className="large">{demandData.unallocated.value}</p>
                  <p className="icon-wrap purple blue-text"><WatchLaterIcon fontSize="small" />{secondsToHoursMins(demandData.unallocated.time)}</p>
                </Grid>
                <Grid item xs={6} className="border-right border-top">
                  <h4><span className="blue-text">{`${demandData.clinical.value}`}</span> Clinical</h4>
                  <p className="icon-wrap"><WatchLaterIcon fontSize="small" color="disabled" />{secondsToHoursMins(demandData.clinical.time)}</p>
                </Grid>
                <Grid item xs={6} className="border-top">
                  <h4><span className="blue-text">{`${demandData.nonClinical.value}`}</span> Non Clinical</h4>
                  <p className="icon-wrap"><WatchLaterIcon fontSize="small" color="disabled" />{secondsToHoursMins(demandData.nonClinical.time)}</p>
                </Grid>
              </Grid>
            </DayViewSection>
          </Grid>
        )}

        <Grid item xs={6}>
          <DayViewSection className="capacity-display" title="Capacity">
            {capacityData && (
              <Grid container>
                <Grid item xs={12}>
                  <h4>Total Staff Availability</h4>
                  <p className="large">{capacityData.totalStaffAvailability.value}</p>
                  <p className="icon-wrap purple blue-text"><WatchLaterIcon fontSize="small" />
                    {secondsToHoursMins(
                      capacityData.totalStaffAvailability.time
                    )}
                  </p>
                </Grid>
                <Grid item xs={6} className="border-right border-top">
                  <h4><span className="blue-text">{`${capacityData.registered.value}`}</span> Registered</h4>
                  <p className="icon-wrap"><WatchLaterIcon fontSize="small" color="disabled" />{secondsToHoursMins(capacityData.registered.time)}</p>
                </Grid>
                <Grid item xs={6} className="border-top">
                  <h4><span className="blue-text">{`${capacityData.nonRegistered.value}`}</span> Non Registered</h4>
                  <p className="icon-wrap"><WatchLaterIcon fontSize="small" color="disabled" />{secondsToHoursMins(capacityData.nonRegistered.time)}</p>
                </Grid>
              </Grid>
            )}
          </DayViewSection>
        </Grid>
        {caseloadData && (
          <Grid item xs={6}>
            <DayViewSection className="demand-display" title="Caseload">
              <Grid container>
                <Grid item xs={12}>
                  <h4>Individual Patients</h4>
                  <p className="large">{caseloadData.individualPatients}</p>
                </Grid>
                <Grid item xs={4} className="border-top purple">
                  <PersonIcon fontSize="large" />
                  <h4><span className="blue-text">{`${caseloadData.single}`}</span> Single</h4>
                </Grid>
                <Grid item xs={4} className="border-right border-top purple multiple-icon">
                  <GroupsIcon fontSize="large" />
                  <h4><span className="blue-text">{`${caseloadData.multiple}`}</span> Multiple</h4>
                </Grid>
                <Grid item xs={4} className="border-top purple">
                  <PeopleIcon fontSize="large" />
                  <h4><span className="blue-text">{`${caseloadData.doubleUp}`}</span> Double Ups</h4>
                </Grid>
              </Grid>
            </DayViewSection>
          </Grid>
        )}

        {contactTimeComplianceData && (
          <Grid item xs={6}>
            <DayViewSection
              className="capacity-display"
              title="Contact Time and Compliance"
            >
              <Grid container>
                <Grid item xs={6} className="border-right">
                  <h4>Check In</h4>
                  <p className="large">{`${contactTimeComplianceData.checkIn}%`}</p>
                </Grid>
                <Grid item xs={6}>
                <WarningIcon fontSize="large" color="warning" className={checkOutWarningStyle()} />
                  <h4>Check Out</h4>
                  <p className="large">{`${contactTimeComplianceData.checkOut}%`}</p>
                </Grid>
                <Grid item xs={12} className="border-top">
                  <WarningIcon fontSize="large" color="warning" className={exceededCapacityWarningStyle()} />
                  <h4><span className="blue-text">{`${contactTimeComplianceData.staffExceededContactTime.value}`}</span> out of <span className="blue-text">{`${contactTimeComplianceData.staffExceededContactTime.maxValue}`}</span> Staff Exceeded Capacity</h4>
                </Grid>
              </Grid>
            </DayViewSection>
          </Grid>
        )}

        {visitOutcomesData && (
          <Grid item xs={6}>
            <DayViewSection className="demand-display" title="Visit Outcomes">
              <Grid container>
                <Grid item xs={6} className="border-right">
                  <h4>Did Not Attend</h4>
                  <p className="large">{`${visitOutcomesData.dna == null ? 'N/A' : visitOutcomesData.dna}`}</p>
                </Grid>
                <Grid item xs={6}>
                  <h4>Cancelled</h4>
                  <p className="large">{`${visitOutcomesData.cancelled}`}</p>
                </Grid>
              </Grid>
            </DayViewSection>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CurrentDayView;

import React, { useState, useEffect } from "react";
import SectionDataGrid from "../../demand_capacity/section_data_grid/SectionDataGrid";
import { hoursComparator } from "../../../utils/utils.js";
import { Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import BlueInfoIcon from '../../../../assets/images/icons/icon-info-blue.svg';
import { Container } from "@mui/material";
import Select from "react-select";
import StaffingLevelsExplained from '../../../../assets/images/staffing_levels_explained.png';

import "./style.scss";

export const StaffingLevelsSection = ({ staffingLevelsData, taxonomy, handleLevelChange, peopleTaxonomy, acuityScores }) => {
  const [loadingData, setLoadingData] = useState(false);

  const acuityScoreTaxonomy = taxonomy.acuity_score
  const personTaxonomy = taxonomy.people
  const visitTaxonomy = taxonomy.event
  const clientTaxonomy = taxonomy.client

  const msToDuration = (params) => {
    return moment.utc(params.value * 1000).format("H[h] mm[m]");
  };

  const columns = [
    {
      field: "band",
      headerName: "Band",
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "qty",
      headerName: "Quantity",
      flex: 1,

      headerAlign: "center",
      align: "center",
    },
    {
      field: "no_of_patients",
      headerName: `No. of ${clientTaxonomy}`,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },
    {
      field: "no_of_visits",
      headerName: `No. of ${visitTaxonomy}`,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },
    {
      field: "avg_visits_per_user",
      headerName: `Avg ${visitTaxonomy} per ${personTaxonomy}`,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },
    {
      field: "avg_visit_length",
      headerName: `Avg ${visitTaxonomy.slice(0, -1)} Length`,
      flex: 1,

      headerAlign: "right",
      align: "right",
      sortComparator: hoursComparator,
      valueGetter: msToDuration,
    },
  ];

  let rows = [];

  if (staffingLevelsData) {
    rows = staffingLevelsData.data.map((entry, index) => {
      entry["id"] = index;
      return entry;
    });
    let totals = staffingLevelsData.totals;
    totals.id = staffingLevelsData.data.length;
    totals.band = 'TOTALS';
    rows.push(totals);
  };

  let levelsForSelect = []
  if (acuityScores) {
     acuityScores.data.map(score => {
      levelsForSelect.push({ value: score.score, label: `Level ${score.score}`})
    })
  }

  return (
    <Container maxWidth="xl" >
      { staffingLevelsData && <div className="staffing-levels"> 
        <Paper elevation={3} square={true} className="section-card">
          <div className="section-card-header">
            <h4 className="title">{peopleTaxonomy} Level Trends</h4>
            <a className="btn btn-small btn-outline btn-popup" data-toggle="modal" href="#staffing_levels_modal"><img className="margin_right_5" src={BlueInfoIcon} />Chart Explained</a>
            {
              loadingData && <span><CircularProgress color="inherit" size={20}/></span>
            }
          </div>
        <div className="section-card-body">
          <div className="section-totals">
            <h3>
              <span className="total-number">{staffingLevelsData.total_staff}</span>{" "}
                Total {peopleTaxonomy} Working
            </h3>
            <Select
              options={levelsForSelect}
              placeholder={acuityScoreTaxonomy}
              onChange={(e) => handleLevelChange(e.value)}
            />
          </div>
          <SectionDataGrid
            align={"right"}
            rows={rows}
            columns={columns}
            hideFooter={true}
          />
        </div>
      </Paper>
        <div className="modal hide" id="staffing_levels_modal">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">×</button>
            <h3>{peopleTaxonomy} Chart Explained</h3>
          </div>
          <div className="modal-body">
            <p>The {peopleTaxonomy} Level Trends section allows you to see the number of patients and {visitTaxonomy.toLowerCase()} for each Band of {personTaxonomy.toLowerCase()}. It also shows you the average number of {visitTaxonomy.toLowerCase()} per {personTaxonomy.toLowerCase()} for the chosen date range. You can also see average {visitTaxonomy.slice(0, -1).toLowerCase()} length per Band of {personTaxonomy}.</p>
            <p>You can further filter these results using the dropdown in the top right to see {visitTaxonomy.toLowerCase()} for a specific {acuityScoreTaxonomy}. Clicking "Export" will allow you to download a .CSV file (spreadsheet file type) file containing this data.</p>
            <img src={StaffingLevelsExplained} />
          </div>
        </div>
      </div>
    }
  </Container>
  );
};

export default StaffingLevelsSection;

import React, { useState, useEffect } from "react";
import Select from "react-select";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DatePicker } from "@mui/lab";
import {
  sub,
  add,
  min,
  endOfMonth,
  startOfMonth,
  isAfter,
  startOfWeek,
} from "date-fns";
import SectionCard from "../section_card/SectionCard";

import "./style.scss";

const dateNow = new Date();
const oneMonthAgo = sub(dateNow, { months: 1 });
const endDate = endOfMonth(oneMonthAgo);
const lastSunday = startOfWeek(dateNow);
const secondLastMonday = sub(lastSunday, { days: 6 });
const startOfLastMonth = startOfMonth(oneMonthAgo);

export const DemandCapacityFilterForm = ({
  handleSubmit,
  dateFrom,
  dateTo,
  groupsForSelect,
  loadingData,
  setDateFrom,
  setDateTo,
  selectedTimePeriod,
  setSelectedTimePeriod,
  setSelectedGroups,
  selectedGroups,
  setDateToCompare,
  setDateFromCompare,
  disableBtn,
  setDisableBtn,
  dateRangeReminder,
  setDateRangeReminder,
  isOpenFrom,
  setIsOpenFrom,
  isOpenTo,
  setIsOpenTo,
  selectedDisplayBy,
  setSelectedDisplayBy,
  isDisabled,
  setIsDisabled,
  datePickerView, 
  setDatePickerView,
  datePickerOpenTo,
  setDatePickerOpenTo,
  isNotMondayWhenDisplayedByDay
}) => {
  // use state declarations
  const [maximumStartDate, setMaximumStartDate] = useState(startOfLastMonth);

  // Setting date ranges once selected in the drop down
  const setDateRangeToLastMonth = () => {
    const startDate = startOfLastMonth;
    setDateTo(endDate);
    setDateFrom(startDate);

    const endDateCompare = endOfMonth(sub(startDate, { months: 1 }));
    const startDateCompare = startOfMonth(endDateCompare);
    setDateFromCompare(startDateCompare);
    setDateToCompare(endDateCompare);
    setDateRangeReminder("");
  };

  const setDateRangeToLastQuarter = () => {
    const currentMonth = dateNow.getMonth();
    const currentYear = dateNow.getFullYear();

    if (currentMonth < 3) {
      const newFromDate = new Date(currentYear - 1, 9, 1);
      setDateFrom(newFromDate);

      const newToDate = new Date(currentYear - 1, 11, 31);
      setDateTo(newToDate);
      const endDateCompare = endOfMonth(sub(newFromDate, { months: 1 }));
      const startDateCompare = startOfMonth(sub(endDateCompare, { months: 2 }));
      setDateFromCompare(startDateCompare);
      setDateToCompare(endDateCompare);
    } else if (currentMonth >= 3 && currentMonth < 6) {
      const newFromDate = new Date(currentYear, 0, 1);
      setDateFrom(newFromDate);

      const newToDate = new Date(currentYear, 2, 31);
      setDateTo(newToDate);
      const endDateCompare = endOfMonth(sub(newFromDate, { months: 1 }));
      const startDateCompare = startOfMonth(sub(endDateCompare, { months: 2 }));
      setDateFromCompare(startDateCompare);
      setDateToCompare(endDateCompare);
    } else if (currentMonth >= 6 && currentMonth < 9) {
      const newFromDate = new Date(currentYear, 3, 1);
      setDateFrom(newFromDate);

      const newToDate = new Date(currentYear, 5, 30);
      setDateTo(newToDate);
      const endDateCompare = endOfMonth(sub(newFromDate, { months: 1 }));
      const startDateCompare = startOfMonth(sub(endDateCompare, { months: 2 }));
      setDateFromCompare(startDateCompare);
      setDateToCompare(endDateCompare);
    } else if (currentMonth >= 9 && currentMonth <= 11) {
      const newFromDate = new Date(currentYear, 6, 1);
      setDateFrom(newFromDate);

      const newToDate = new Date(currentYear, 8, 30);
      setDateTo(newToDate);
      const endDateCompare = endOfMonth(sub(newFromDate, { months: 1 }));
      const startDateCompare = startOfMonth(sub(endDateCompare, { months: 2 }));
      setDateFromCompare(startDateCompare);
      setDateToCompare(endDateCompare);
    }

    setDateRangeReminder("");
  };

  const setDateRangeToLastSixMonths = () => {
    const startDate = startOfMonth(sub(dateNow, { months: 6 }));
    setDateTo(endDate);
    setDateFrom(startDate);

    const endDateCompare = endOfMonth(sub(startDate, { months: 1 }));
    const startDateCompare = startOfMonth(sub(endDateCompare, { months: 5 }));
    setDateFromCompare(startDateCompare);
    setDateToCompare(endDateCompare);
    setDateRangeReminder("");
  };

  const setDateRangeToLastYear = () => {
    const startDate = startOfMonth(sub(dateNow, { months: 12 }));
    setDateTo(endOfMonth(oneMonthAgo));
    setDateFrom(startDate);

    const endDateCompare = endOfMonth(sub(startDate, { months: 1 }));
    const startDateCompare = startOfMonth(sub(endDateCompare, { months: 11 }));
    setDateFromCompare(startDateCompare);
    setDateToCompare(endDateCompare);
    setDateRangeReminder("");
  };

  // When datepickers are changed
  const handleOnChangeFrom = (newValue) => {
    const newValueSub = sub(dateTo, { months: 13 });
    const maxValueSub = endOfMonth(add(newValue, { months: 11 }));

    setDateFrom(newValue);
    setSelectedTimePeriod(null);

    setDateRangeReminder("");

    if(selectedDisplayBy == 'Week') {
      setDateTo(add(newValue, { days: 6 }));
    } else {
      if (isAfter(newValue, dateTo)) {
        setDateTo(add(newValue, { months: 1 }));
        setSelectedTimePeriod(null);
        setDateRangeReminder("End date must be after start date");
      }
      
      if (isAfter(dateTo, maxValueSub)) {
        setDateTo(maxValueSub);
        setDateRangeReminder("Limited to 12 months");
      }
    }
  };

  const handleOnChangeTo = (newValue) => {
    newValue = endOfMonth(newValue);
    setDateTo(newValue);
    setSelectedTimePeriod(null);
    setDateRangeReminder("");
    const maxValueSub = endOfMonth(add(dateFrom, { months: 11 }));
    if (isAfter(newValue, maxValueSub)) {
      setDateTo(maxValueSub);
    }
  };

  // Select form options for preselected date ranges
  const optionsForPreselected = [
    { value: "last-month", label: "Last Month" },
    { value: "last-quarter", label: "Last Quarter" },
    { value: "last-six", label: "Last 6 Months" },
    { value: "last-year", label: "Last Year" },
  ];

  const optionsForDisplayBy = [
    { value: "week", label: "Week" },
    { value: "month", label: "Month" }
  ];

  // Handle select change for predefined time periods
  const handleSelectChange = (selection) => {
    if (selection === null) {
      setSelectedTimePeriod("Select Time Period");
    } else if (selection.value === "last-month") {
      setSelectedTimePeriod("Last Month");
      setDateRangeToLastMonth();
    } else if (selection.value === "last-quarter") {
      setSelectedTimePeriod("Last Quarter");
      setDateRangeToLastQuarter();
    } else if (selection.value === "last-six") {
      setSelectedTimePeriod("Last Six Months");
      setDateRangeToLastSixMonths();
    } else if (selection.value === "last-year") {
      setSelectedTimePeriod("Last Year");
      setDateRangeToLastYear();
    }
  };

  // Handle display by change for predefined options
  const handleDisplayByChange = (selection) => {
    if (selection.value === "month") {
      setSelectedDisplayBy("Month");
      setDatePickerView(["month", "year"]);
      setDatePickerOpenTo("month");
      setIsDisabled(false);
      setDateRangeToLastMonth();
      setMaximumStartDate(startOfLastMonth);
    } else if (selection.value === "week") {
      setSelectedDisplayBy("Week");
      setDatePickerView(["year", "month", "day"]);
      setDatePickerOpenTo("day");
      setSelectedTimePeriod(null);
      setIsDisabled(true);
      setDateFrom(secondLastMonday);
      setDateTo(lastSunday);
      setMaximumStartDate(secondLastMonday);
    }
  };

  // Handle Group Change
  const handleGroupChange = (selection) => {
    const numberOfSelectedGroups = selection.length;

    if(numberOfSelectedGroups > 1){
      if(selection[numberOfSelectedGroups - 1].value === "") {
        selection.splice(0, numberOfSelectedGroups - 1);
      } else if (selection[0].value === "") {
        selection.shift();
      }
    }

    setSelectedGroups(selection);
    setDisableBtn(false);
  };

  return (
    <SectionCard>
      <form onSubmit={(e) => handleSubmit(e)} className="filter-form">
        <LocalizationProvider dateAdapter={DateAdapter}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p>
                The maximum range of 12 months will automatically be applied
                from Start Date if your date selection exceeds this
              </p>
            </Grid>

            <Grid item xs={2}>
              <Select
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                id='display_select_id'
                key={`my_unique_select_key__${selectedDisplayBy}`}
                value={selectedDisplayBy || "Select Display By"}
                label="Select Display By"
                options={optionsForDisplayBy}
                openMenuOnFocus={true}
                isMulti={false}
                isDisabled={loadingData}
                closeMenuOnSelect={true}
                placeholder={
                  selectedDisplayBy === null
                    ? "Select Display By..."
                    : selectedDisplayBy
                }
                onChange={(displayBy) => handleDisplayByChange(displayBy)}
              ></Select>
            </Grid>

            <Grid item xs={3}>
              <div className="date-pickers">
                <DatePicker
                  open={isOpenFrom}
                  onOpen={() => setIsOpenFrom(true)}
                  onClose={() => setIsOpenFrom(false)}
                  inputFormat="dd/MM/yyyy"
                  openTo={datePickerOpenTo}
                  views={datePickerView}
                  label="Start date"
                  maxDate={maximumStartDate}
                  value={dateFrom}
                  disabled={loadingData}
                  shouldDisableDate={isNotMondayWhenDisplayedByDay}
                  onChange={(newValue) => {
                    handleOnChangeFrom(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="helper-text"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      onClick={(e) => setIsOpenFrom(true)}
                      {...params}
                      size="normal"
                    />
                  )}
                />
                <DatePicker
                  open={isOpenTo}
                  onOpen={() => setIsOpenTo(true)}
                  onClose={ () => {
                      setIsOpenTo(false);
                      if(selectedDisplayBy == 'Week') {
                        setDateTo(add(dateFrom, { days: 6 }));
                      }
                    }
                  }
                  inputFormat="dd/MM/yyyy"
                  openTo={datePickerOpenTo}
                  views={datePickerView}
                  label="End date"
                  minDate={endOfMonth(dateFrom)}
                  maxDate={min([
                    endOfMonth(add(dateFrom, { months: 11 })),
                    endOfMonth(oneMonthAgo),
                  ])}
                  value={dateTo}
                  disabled={isDisabled}
                  shouldDisableDate={isNotMondayWhenDisplayedByDay}
                  onChange={(newValue) => {
                    handleOnChangeTo(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      {...params}
                      onClick={(e) => setIsOpenTo(true)}
                      helperText={dateRangeReminder}
                      size="normal"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={0.3}>
              <p className="or-select-text">Or</p>
            </Grid>

            <Grid item xs={2}>
              <Select
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                key={`my_unique_select_key__${selectedTimePeriod}`}
                value={selectedTimePeriod || "Select Time Period"}
                label="Select Time Period"
                options={optionsForPreselected}
                openMenuOnFocus={true}
                isMulti={false}
                isDisabled={isDisabled}
                closeMenuOnSelect={true}
                placeholder={
                  selectedTimePeriod === null
                    ? "Select time period..."
                    : selectedTimePeriod
                }
                onChange={(timePeriod) => handleSelectChange(timePeriod)}
              ></Select>
            </Grid>
            <Grid item xs={2}>
              <Select
                options={groupsForSelect}
                defaultValue={selectedGroups}
                openMenuOnFocus={true}
                isMulti={true}
                isDisabled={loadingData}
                closeMenuOnSelect={false}
                placeholder="Select Group"
                id="select_group"
                onChange={(selection) => handleGroupChange(selection)}
              />
            </Grid>
            <Grid item xs={2}>
              <div className="text-left mt-2">
                <LoadingButton
                  disabled={disableBtn}
                  className="apply-filters-btn"
                  loading={loadingData}
                  variant="contained"
                  type="submit"
                  size="small"
                >
                  Apply filters
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </form>
    </SectionCard>
  );
};

export default DemandCapacityFilterForm;

import React from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid';

import './style.scss';

export const SectionDataGrid = ({rows, columns, hideFooter = false, getCellClassName = null}) => {

  const [pageSize, setPageSize] = React.useState(14);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }}/>
      </GridToolbarContainer>
    );
  }
 
  const allRows = rows.length;

  return (
    <div className='section-data-grid-wrapper'>
      <DataGrid
        rowHeight={25}
        density="comfortable"
        autoHeight
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, (allRows) ]}
        disableSelectionOnClick
        hideFooter={hideFooter}
        getCellClassName={getCellClassName}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  )
}

export default SectionDataGrid;
import React from "react";
import SectionCard from "../../demand_capacity/section_card/SectionCard";
import SectionDataGrid from "../../demand_capacity/section_data_grid/SectionDataGrid";
import { hoursComparator } from "../../../utils/utils.js";
import WarningIcon from '@mui/icons-material/Warning';

const CapacityDetailSection = ({ loadingData, staffBands }) => {
  const secToHour = (params) => {
    let seconds = params.value;
    let hours = seconds / 3600;
    let flooredHours = Math.floor(hours);

    let minutes = ((hours - flooredHours) * 60).toFixed(0);
    
    if(seconds < 0) {
      flooredHours = Math.ceil(hours);
      minutes = Math.abs((hours - flooredHours) * 60).toFixed(0);
    }

    return `${flooredHours}h ${minutes}m`;
  };

  const columns = [
    {
      field: "warning",
      headerName: "",
      flex: 0.1,
      headerAlign: "left",
      renderCell: (params) => (
        <div>
          <WarningIcon fontSize="medium" className={params.row.warning} style={{ color: '#ed6c02' }} />
        </div>
      ),
    },
    {
      field: "band",
      headerName: "Band",
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "qty",
      headerName: "Quantity",
      flex: 1,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "capacity",
      headerName: "Available Capacity",
      flex: 1,
      headerAlign: "right",
      valueGetter: secToHour,
      sortComparator: hoursComparator,
      align: "right",
    },
    {
      field: "allocated_travel_time",
      headerName: "Allocated Travel Time",
      flex: 1,
      headerAlign: "right",
      valueGetter: secToHour,
      sortComparator: hoursComparator,
      align: "right",
    },
    {
      field: "demand_plus_travel_time",
      headerName: "Demand Plus Travel Time",
      flex: 1,
      headerAlign: "right",
      valueGetter: secToHour,
      sortComparator: hoursComparator,
      align: "right",
    },
    {
      field: "dif",
      headerName: "Difference",
      flex: 1,
      headerAlign: "right",
      valueGetter: secToHour,
      sortComparator: hoursComparator,
      align: "right",
    },
  ];

  let rows = [];

  if (staffBands) {
    rows = staffBands.data.map((entry, index) => {
      entry["id"] = index;
      return entry;
    });
  }

  const getCellClassName = (params) => {
    return (params.row.warning == 'show_warning' ? (params.field == 'dif' ? 'medium_orange_background' : 'light_orange_background') : 'white_background');
  };

  return (
    <div className="capacity-details-demand-wrapper">
      <SectionCard title="Capacity Detail" loadingData={loadingData}>
        {staffBands && (
          <div>
            <div className="section-totals">
              <h3>
                <span className="total-number">{staffBands.totalStaff}</span>{" "}
                Total Staff
              </h3>
            </div>
            <SectionDataGrid
              align={"right"}
              rows={rows}
              columns={columns}
              hideFooter={true}
              getCellClassName={getCellClassName}
            />
          </div>
        )}
      </SectionCard>
    </div>
  );
};

export default CapacityDetailSection;

import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  sub,
  endOfMonth,
  startOfMonth,
  format,
  differenceInCalendarMonths,
  differenceInDays,
} from "date-fns";

import { getRequest } from "../../services/base_requests";
import TrendsSection from "../../components/demand_capacity/trends_section/TrendsSection";
import InterventionDemandSection from "../../components/demand_capacity/intervention_demand_section/InterventionDemandSection";
import StaffBandsSection from "../../components/demand_capacity/staff_bands_section/StaffBandsSection";
import DemandCapacityFilterForm from "../../components/demand_capacity/demand_capacity_filter_form/DemandCapacityFilterForm";
import CapacityDetailSection from "../../components/weekly_planner/capacity_detail_section/CapacityDetailSection";

import "./style.scss";

const dateNow = new Date();
const startDate = startOfMonth(sub(dateNow, { months: 6 }));
const endDate = endOfMonth(sub(dateNow, { months: 1 }));
const startDateCompare = startOfMonth(sub(startDate, { months: 6 }));
const endDateCompare = endOfMonth(sub(startDate, { months: 1 }));

export const DemandCapacityPage = ({ groups }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [trendsData, setTrendsData] = useState(undefined);
  const [interventionDemand, setInterventionDemand] = useState(undefined);
  const [staffBands, setStaffBands] = useState(undefined);
  const [trends, setTrends] = useState(undefined);
  const [trendsCompare, setTrendsCompare] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(startDate);
  const [dateTo, setDateTo] = useState(endDate);
  const [dateFromCompare, setDateFromCompare] = useState(startDateCompare);
  const [dateToCompare, setDateToCompare] = useState(endDateCompare);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [viewComparisonMode, setViewComparisonMode] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);
  const [disableBtn, setDisableBtn] = useState(true);
  const [dateRangeReminder, setDateRangeReminder] = useState("");
  const [isOpenFrom, setIsOpenFrom] = useState(false);
  const [isOpenTo, setIsOpenTo] = useState(false);
  const [isOpenCurrentFrom, setIsOpenCurrentFrom] = useState(false);
  const [isOpenCurrentTo, setIsOpenCurrentTo] = useState(false);
  const [isOpenCompareFrom, setIsOpenCompareFrom] = useState(false);
  const [selectedDisplayBy, setSelectedDisplayBy] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [datePickerView, setDatePickerView] = useState(["month", "year"]);
  const [datePickerOpenTo, setDatePickerOpenTo] = useState("month");

  const groupsForSelect = [ { value: "", label: "All groups" }, 
                            ...groups.map((group) => { return { value: group.id, label: group.name }; }) 
                          ];

  const currentPeriodLength = () => {
    if (selectedDisplayBy == 'Week') {
      return differenceInDays(dateTo, dateFrom) + 1;
    } else {
      return differenceInCalendarMonths(dateTo, dateFrom) + 1;
    }
  }

  const updateViewMode = () => {
    if (viewComparisonMode === false) {
      let dateFromCompareValue;
      let dateToCompareValue;

      if (selectedDisplayBy == 'Week') {
        dateFromCompareValue = sub(dateFrom, { days: currentPeriodLength() });
        dateToCompareValue = sub(dateTo, { days: currentPeriodLength() });
      } else {
        dateFromCompareValue = startOfMonth(sub(dateFrom, { months: currentPeriodLength() }));
        dateToCompareValue = endOfMonth(sub(dateTo, { months: currentPeriodLength() }));
      }

      setDateFromCompare(dateFromCompareValue);
      setDateToCompare(dateToCompareValue);
    }

    setViewComparisonMode(!viewComparisonMode);
  };

  const getAllData = () => {
    setLoadingData(true);
    const selectedGroupsValue = selectedGroups.map(group => group.value);
    
    Promise.all([
      getRequest({
        url: "/api/dashboard/v1/demand-capacity",
        params: {
          dateFrom: format(dateFrom, "yyyy-MM-dd"),
          dateTo: format(dateTo, "yyyy-MM-dd"),
          groups: selectedGroupsValue.join(),
          displayed_by: selectedDisplayBy
        },
      }),
      getRequest({
        url: "/api/dashboard/v1/demand-capacity/trends",
        params: {
          dateFrom: format(dateFromCompare, "yyyy-MM-dd"),
          dateTo: format(dateToCompare, "yyyy-MM-dd"),
          groups: selectedGroupsValue.join(),
          displayed_by: selectedDisplayBy
        },
      }),
    ])
      .then(async (responses) => {
        setLoadingData(false);
        const trendsResponse = responses[0];
        const compareResponse = responses[1];


        const trendsData = await trendsResponse.json();
        const compareData = await compareResponse.json();

        setTrendsData(trendsData)

        setInterventionDemand(trendsData.interventDemand);
        setStaffBands(trendsData.staffBands);
        setTrends(trendsData.trends);
        setTrendsCompare(compareData.trends);
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loadingData) return;

    getAllData();
  };

  // Handle displaying only Mondays when selected displayed by is Week
  const isNotMondayWhenDisplayedByDay = (date) => {
    if(selectedDisplayBy == 'Week'){
      return date.getDay() != 1;
    } else {
      return false;
    }
  };

  return (
    <div className="page-container">
      <div className="page-title-container">
        <Container maxWidth="xl">
          <h1>Capacity vs Demand Trends</h1>
        </Container>
      </div>

      <Container maxWidth="xl">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingData && !trends && !interventionDemand && !staffBands}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div>
          <DemandCapacityFilterForm
            isOpenFrom={isOpenFrom}
            setIsOpenFrom={setIsOpenFrom}
            isOpenTo={isOpenTo}
            setIsOpenTo={setIsOpenTo}
            dateRangeReminder={dateRangeReminder}
            setDateRangeReminder={setDateRangeReminder}
            selectedGroups={selectedGroups}
            disableBtn={disableBtn}
            setDisableBtn={setDisableBtn}
            selectedTimePeriod={selectedTimePeriod}
            setSelectedTimePeriod={setSelectedTimePeriod}
            handleSubmit={handleSubmit}
            dateFrom={dateFrom}
            dateTo={dateTo}
            groupsForSelect={groupsForSelect}
            loadingData={loadingData}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
            setSelectedGroups={setSelectedGroups}
            setDateFromCompare={setDateFromCompare}
            setDateToCompare={setDateToCompare}
            selectedDisplayBy={selectedDisplayBy}
            setSelectedDisplayBy={setSelectedDisplayBy}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            datePickerView={datePickerView}
            setDatePickerView={setDatePickerView}
            datePickerOpenTo={datePickerOpenTo}
            setDatePickerOpenTo={setDatePickerOpenTo}
            isNotMondayWhenDisplayedByDay={isNotMondayWhenDisplayedByDay}
          />
          {trendsData && (
            <>
            <TrendsSection
              isOpenCompareFrom={isOpenCompareFrom}
              setIsOpenCompareFrom={setIsOpenCompareFrom}
              isOpenCurrentFrom={isOpenCurrentFrom}
              setIsOpenCurrentFrom={setIsOpenCurrentFrom}
              isOpenCurrentTo={isOpenCurrentTo}
              setIsOpenCurrentTo={setIsOpenCurrentTo}
              dateRangeReminder={dateRangeReminder}
              setDateRangeReminder={setDateRangeReminder}
              selectedTimePeriod={selectedTimePeriod}
              setSelectedTimePeriod={setSelectedTimePeriod}
              trends={trends}
              trendsCompare={trendsCompare}
              loadingData={loadingData}
              dateFrom={dateFrom}
              dateTo={dateTo}
              viewComparisonMode={viewComparisonMode}
              updateViewMode={updateViewMode}
              setDateTo={setDateTo}
              setDateFrom={setDateFrom}
              dateFromCompare={dateFromCompare}
              dateToCompare={dateToCompare}
              setDateFromCompare={setDateFromCompare}
              setDateToCompare={setDateToCompare}
              getAllData={getAllData}
              selectedDisplayBy={selectedDisplayBy}
              setSelectedDisplayBy={setSelectedDisplayBy}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
              datePickerView={datePickerView}
              setDatePickerView={setDatePickerView}
              datePickerOpenTo={datePickerOpenTo}
              setDatePickerOpenTo={setDatePickerOpenTo}
              isNotMondayWhenDisplayedByDay={isNotMondayWhenDisplayedByDay}
              currentPeriodLength={currentPeriodLength}
            />
            <InterventionDemandSection
              interventionDemand={interventionDemand}
              loadingData={loadingData}
            />
            <StaffBandsSection
              staffBands={staffBands}
              loadingData={loadingData}
            />
            <CapacityDetailSection
              loadingData={loadingData}
              staffBands={staffBands}
            />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default DemandCapacityPage;

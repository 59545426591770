import React, { useRef, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { Paper } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import BlueInfoIcon from '../../../../assets/images/icons/icon-info-blue.svg';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import Map, {Marker} from 'mapbox-gl';

mapboxgl.accessToken = "pk.eyJ1IjoibGlxdWlkYnJvbnplIiwiYSI6ImNsOGQxM2N0ZzE2Z3M0MXFreWJndGh2bHUifQ.VoU4Eai03Rp5RD5LAoDzbQ";

import "./style.scss";

export const VisitsByPostCode = ({ taxonomy, visitsByPostCodeData, acuityScores }) => {
  const visitTaxonomy = taxonomy.event;
  const acuityScoreTaxonomy = taxonomy.acuity_score;

  if (visitsByPostCodeData !== null && visitsByPostCodeData !== undefined && visitsByPostCodeData.length !== 0) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const lng = visitsByPostCodeData[0].long;
    const lat = visitsByPostCodeData[0].lat;
    const [zoom, setZoom] = useState(10);

    // Filtering the data to update the map via the tabs
    const [filter, setFilter] = useState(visitsByPostCodeData);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [markers, setMarkers] = useState([]);

    const handleFilterChange = (event, newValue) => {
      setSelectedTabIndex(newValue);
      updateMap(newValue);
    };

    const updateMap = (filterValue) => {
      if (filterValue === "all") {
        setFilter(visitsByPostCodeData) 
      } else {
        const label = `Level ${filterValue}`;
        setFilter(visitsByPostCodeData.filter(visit => visit.label === label))
      }
    };

    useEffect(() => {
      if (map.current) {
        // remove existing markers
        markers.forEach((marker) => marker.remove());
      }
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [lng, lat],
        zoom: zoom
      });
      // add new markers
      const newMarkers = filter.map((index) => {
        const marker = new mapboxgl.Marker({
          color: index.color,
        })
        .setLngLat([index.long, index.lat])
        .setPopup(new mapboxgl.Popup().setText(index.postcode))
        .addTo(map.current);
        return marker;
      });
      setMarkers(newMarkers);
    }, [filter]);


 
  return (
    <Container maxWidth="xl">
      <div className="module visits-by-postcode">
        <Paper elevation={3} square={true}>
          <h4 className="title">{visitTaxonomy} By Post Code <a className="btn btn-small btn-outline btn-popup" data-toggle="modal" href="#post_code_modal"><img className="margin_right_5" src={BlueInfoIcon} />Maps Explained</a></h4>
          <Tabs value={selectedTabIndex} onChange={handleFilterChange} aria-label="filter tabs">
            <Tab label="All" value={0}/>
            {acuityScores?.data?.map((score, index) => {
              const tabValue = index + 1;
              return <Tab label={`Level ${score.score}`} value={tabValue} key={tabValue} />;
            })}
          </Tabs>
          <div ref={mapContainer} className="map-container" />
        </Paper>
      </div>
      <div className="modal hide" id="post_code_modal">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal">×</button>
          <h3>Maps Explained</h3>
        </div>
        <div className="modal-body">
            <p>{visitTaxonomy} by Post Code section allows you to see a map which the colored dots represent the number of visits per {acuityScoreTaxonomy} by postcode.</p>
            <p>Using the headings above the map, this can be filtered further to only show {visitTaxonomy} for a single {acuityScoreTaxonomy}</p>
        </div>
        <div className="modal-footer">
          <a href="#" className="btn" data-dismiss="modal">Close</a>
        </div>
      </div>
    </Container>
    );
  } else {
    return (
    <Container maxWidth="xl">
      <div className="module visits-by-postcode">
        Loading data.
      </div>
    </Container>
      )
  }
}

export default VisitsByPostCode;
import React from 'react';
import { Container } from "@mui/material";
import BlueInfoIcon from '../../../../assets/images/icons/icon-info-blue.svg';
import SummaryCardsSnapshot from '../../../../assets/images/summary_cards_snapshot.png';

export const SummaryCards = ({ taxonomy, summaryCards, acuityScores }) => {
  const titleTaxonomy = taxonomy.acuity_score;
  const clientTaxonomy = taxonomy.client;
  const visitTaxonomy = taxonomy.event;

  const secondsToHoursMins = (seconds) => {
      let totalMinutes = seconds / 60;
      let totalHours = Math.floor(totalMinutes / 60);
      let remainingMinutes = Math.floor(totalMinutes % 60);
      return totalHours + "h " + remainingMinutes + "m";
  };

  return (
  	<Container maxWidth="xl"> 
  	{summaryCards && <> 
	  	<a className="btn btn-small btn-outline btn-popup" data-toggle="modal" href="#levels_explained_modal"><img className="margin_right_5" src={BlueInfoIcon} />Levels Explained</a>
	  	<a className="btn btn-small btn-outline btn-popup" data-toggle="modal" href="#summary_cards_modal"><img className="margin_right_5" src={BlueInfoIcon} />Summary Cards</a>
	    <div className="summary-cards">
	      {summaryCards.map((value, index) => {
	      	const levelClass = (value.level).replace(/\s+/g, '-').toLowerCase();
	        return <div key={index} id={levelClass} className="item">
	        <h4 style={{ backgroundColor: value.colour }}>{value.level}</h4>
	        <div className="stat">
	          <span>{clientTaxonomy}</span>
	          <p>{value.clients}</p>
	        </div>
	        <div className="stat">
	          <span>{visitTaxonomy}</span>
	          <p>{value.events}</p>
	        </div>
	        <div className="stat">
	         <span>% of {visitTaxonomy}</span>
	          <p>{value.percentage}%</p>
	        </div>
	        <div className="stat">
	          <span>Hours</span>
	          <p>{secondsToHoursMins(value.seconds)}</p>
	        </div>
	        </div>
	      })}
	    </div>
	    <div className="modal hide" id="levels_explained_modal">
		    <div className="modal-header">
		      <button type="button" className="close" data-dismiss="modal">×</button>
		      <h3>{titleTaxonomy} Explained</h3>
		    </div>
		    <div className="modal-body">
					{ acuityScores?.data?.map((score, index) => {
						return <div key={index} className="acuity_score_card"><div className="acuity_score_score"><span className="acuity_score_circle" style={{ backgroundColor: score.colour }}>{ score.score }</span></div><div className="acuity_score_info"><b>{score.title}</b><p>{ score.description } </p></div></div>
					})}
		    </div>
		    <div className="modal-footer">
		      <a href="#" className="btn" data-dismiss="modal">Close</a>
		    </div>
	    </div>
	    <div className="modal hide" id="summary_cards_modal">
		    <div className="modal-header">
		      <button type="button" className="close" data-dismiss="modal">×</button>
		      <h3>Summary Cards Explained</h3>
		    </div>
		    <div className="modal-body">
		        <p>The Summary Cards section shows an overview of the day for the selected team/s, showing the number of {clientTaxonomy} and {visitTaxonomy} for each {titleTaxonomy}.</p>
						<p>The "% of {visitTaxonomy}" is the percentage of {visitTaxonomy} that have a {titleTaxonomy} between 1 and 5. {visitTaxonomy} with a {titleTaxonomy} of 0 are not factored in to any calculations on this page.</p>
						<img className="margin_bottom_10" src={SummaryCardsSnapshot} />
		    </div>
		    <div className="modal-footer">
		      <a href="#" className="btn" data-dismiss="modal">Close</a>
		    </div>
	    </div>
	    </>}
   </Container>
  );
};

export default SummaryCards; 
import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import { Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import BlueInfoIcon from '../../../../assets/images/icons/icon-info-blue.svg';
import SPCexplained from '../../../../assets/images/spc_explained.png';
import SPClimitsExplained from '../../../../assets/images/spc_limits_explained.png';

export const DurationOfVisits = ({ durationOfVisitsData, loadingData, taxonomy }) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip, annotationPlugin);
  const [selectedView, setSelectedView] = useState('Summary View');
  const [checked, setChecked] = useState(true);

  const visitTaxonomy = taxonomy.event
  const acuityScoreTaxonomy = taxonomy.acuity_score

  const average = (ctx) => {
    if (selectedView !== "Summary View") {
      const values = durationOfVisitsData.datasets[selectedView].data;
      let average = values.reduce((a, b) => a + b, 0) / values.length;
      return parseFloat(average.toFixed(2))
    };
  };

  const standardDeviation = (ctx) => {
    if (selectedView !== "Summary View") {
      const values = durationOfVisitsData.datasets[selectedView].data;
      const n = values.length;
      const mean = average(ctx);
      let stDeviation = Math.sqrt(values.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
      return stDeviation*3
    };
  }

  const annotation1 = {
    display: selectedView !== 'Summary View',
    type: 'line',
    borderColor: 'black',
    borderWidth: 2,
    scaleID: 'y',
    label: {
      display: (ctx) => ctx.hovered,
      backgroundColor: 'rgba(102, 102, 102, 0.5)',
      yAdjust: 28,
      color: 'black',
      drawTime: 'afterDatasetsDraw',
      content: (ctx) => (average(ctx)),
      position: (ctx) => ctx.hoverPosition
    },
    enter(ctx, event) {
      ctx.hovered = true;
      ctx.hoverPosition = (event.x / ctx.chart.chartArea.width * 100) + '%';
      ctx.chart.update();
    },
    leave(ctx, event) {
      ctx.hovered = false;
      ctx.chart.update();
    },
    value: (ctx) => average(ctx)
  }

  const annotation2 = {
    display: selectedView !== 'Summary View',
    type: 'line',
    borderColor: 'rgba(102, 102, 102, 0.5)',
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 3,
    label: {
      display: (ctx) => ctx.hovered,
      backgroundColor: 'rgba(102, 102, 102, 0.5)',
      yAdjust: 28,
      color: 'black',
      drawTime: 'afterDatasetsDraw',
      content: (ctx) => (average(ctx) + standardDeviation(ctx)).toFixed(2),
      position: (ctx) => ctx.hoverPosition
    },
    scaleID: 'y',
    enter(ctx, event) {
      ctx.hovered = true;
      ctx.hoverPosition = (event.x / ctx.chart.chartArea.width * 100) + '%';
      ctx.chart.update();
    },
    leave(ctx, event) {
      ctx.hovered = false;
      ctx.chart.update();
    },
    value: (ctx) => average(ctx) + standardDeviation(ctx)
  }

  const annotation3 = {
    display: (ctx) => selectedView !== 'Summary View' && average(ctx) - standardDeviation(ctx) > 0,
    type: 'line',
    borderColor: 'rgba(102, 102, 102, 0.5)',
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 3,
    label: {
      display: (ctx) => ctx.hovered,
      backgroundColor: 'rgba(102, 102, 102, 0.5)',
      yAdjust: -28,
      color: 'black',
      drawTime: 'afterDatasetsDraw',
      content: (ctx) => (average(ctx) - standardDeviation(ctx)).toFixed(2),
      position: (ctx) => ctx.hoverPosition
    },
    scaleID: 'y',
    enter(ctx, event) {
      ctx.hovered = true;
      ctx.hoverPosition = (event.x / ctx.chart.chartArea.width * 100) + '%';
      ctx.chart.update();
    },
    leave(ctx, event) {
      ctx.hovered = false;
      ctx.chart.update();
    },
    value: (ctx) => average(ctx) - standardDeviation(ctx)
  }

  const CHART_OPTIONS_BAR = {
    maintainAspectRatio: false,
    scales: {
      x: {
        align: "center",
        ticks: {
          font: {
            size: 14,
            weight: "bold"
          }
        },
        grid: {
          offset: true
       }
      },
      y: {
        title: {
          display: true,
          text: "Minutes",
          align: "center",
          font: {
            size: 16,
            weight: "bold",
          },
          padding: {bottom: 15}
        },
      },
    },
    plugins: {
      annotation: {
        annotations: {
          annotation1,
          annotation2,
          annotation3,
        }
      },
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 15,
          padding: 10,
          align: 'start',
        },
        title: {
          display: true,
          padding: {
            top: 10,
          },
        },
      },
    },
  };

  const chartDataForSelectedView = () => {
    let datasets = [];
    if (selectedView === "Summary View") {
      datasets = durationOfVisitsData.datasets;
    } else {
      datasets = [durationOfVisitsData.datasets[selectedView]];
    }
    return {
      labels: durationOfVisitsData.labels,
      datasets: datasets,
    };
  };

  const updateView = (event) => {
    event.target.value === 'Summary View' ? setChecked(true) : setChecked(false);
    setSelectedView(event.target.value);
  };

  return (
    <Container maxWidth="xl" className="duration-of-visits">
      { durationOfVisitsData && <div className="section-card">
        <Paper elevation={3} square={true}>
          <div className="section-card-header">
            <h4 className="title"> Duration of {visitTaxonomy}</h4>
            <a className="btn btn-small btn-outline btn-popup" data-toggle="modal" href="#duration_of_visits_modal"><img className="margin_right_5" src={BlueInfoIcon} />SPC Charts</a>
            {
              loadingData && <span><CircularProgress color="inherit" size={20}/></span>
            }
          </div>
          <div className="section-card-body">
            <div className="duration-of-visits-line-chart">
              <label className="duration-of-visits-checkboxes">
                <input type="radio" name="levels" value='Summary View' onChange={updateView} checked={checked}/>
                Summary View
              </label>
                { durationOfVisitsData.datasets.map((dataset, index) => {
                  return (
                    <label className="duration-of-visits-checkboxes" key={index}>
                      <input type="radio" name="levels" value={index} onChange={updateView}/>
                      {dataset.label}
                    </label>
                  )
                })}
                <Line options={CHART_OPTIONS_BAR} data={chartDataForSelectedView()}/>
            </div>
          </div>
        </Paper>
        <div className="modal hide" id="duration_of_visits_modal">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">×</button>
            <h3>Statistical Process Charts (SPC) Explained</h3>
          </div>
          <div className="modal-body">
            <p>This shows the Average Duration of {visitTaxonomy} per given period, broken down by {acuityScoreTaxonomy}.</p>
            <img src={SPCexplained}/>
            <div className="spc-explained">
              <img src={SPClimitsExplained}/>
              <p>This graph can be further filtered via the filter buttons at the top to show a specific {acuityScoreTaxonomy}. This additionally adds in dotted lines showing the mean duration, plus the upper and lower bounds so it is easier to spot abnormalities.</p>
            </div>
          </div>
        </div>
      </div>}
    </Container>
  );
}

export default DurationOfVisits;

import React, { useState } from "react";
import Select from "react-select";
import { Container, Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/lab";
import {
  isEqual,
  sub,
  add,
  min,
  endOfWeek,
  startOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

import SectionCard from "../../demand_capacity/section_card/SectionCard";

import "./style.scss";

const dateNow = new Date();
const oneWeekAgo = sub(dateNow, { weeks: 1 });
const endDate = endOfWeek(oneWeekAgo, { weekStartsOn: 1 });

export const FilterForm = ({
  groupsForSelect,
  selectedGroups,
  loadingData,
  setSelectedGroups,
  handleSubmit,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  selectedTimePeriod,
  setSelectedTimePeriod,
  isOpenFrom,
  setIsOpenFrom,
  isOpenTo,
  setIsOpenTo
}) => {
  const [disableBtn, setDisableBtn] = useState(true);

  // Handle Group Change
  const handleGroupChange = (selection) => {
    const numberOfSelectedGroups = selection.length;

    if(numberOfSelectedGroups > 1){
      if(selection[numberOfSelectedGroups - 1].value === "") {
        selection.splice(0, numberOfSelectedGroups - 1);
      } else if (selection[0].value === "") {
        selection.shift();
      }
    }

    setSelectedGroups(selection);

    if (selection.length === 0) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  const handleOnChangeFrom = (newValue) => {
    setDateFrom(newValue);
  };

  const handleOnChangeTo = (newValue) => {
    setDateTo(newValue);
  };

  // Select form options for preselected date ranges
  const optionsForPreselected = [
    { value: "one-week", label: "1 week" },
    { value: "one-month", label: "1 month" },
    { value: "twelve-weeks", label: "12 weeks" },
    { value: "six-months", label: "6 months" },
    { value: "twelve-months", label: "12 months" },
  ];

  const handleSelectChange = (selection) => {
    if (selection === null) {
      setSelectedTimePeriod("Select Time Period");
    } else if (selection.value === "one-week") {
      setSelectedTimePeriod("1 week");
      setDateRangeToLastWeek();
    } else if (selection.value === "one-month") {
      setSelectedTimePeriod("1 month");
      setDateRangeToLastMonth();
    } else if (selection.value === "twelve-weeks") {
      setSelectedTimePeriod("12 weeks");
      setDateRangeToLastTwelveWeeks();
    } else if (selection.value === "six-months") {
      setSelectedTimePeriod("6 months");
      setDateRangeToLastSixMonths();
    } else if (selection.value === "twelve-months") {
      setSelectedTimePeriod("12 months");
      setDateRangeToLastYear();
    }
  };

  // Setting date ranges once selected in the drop down
  const setDateRangeToLastWeek = () => {
    const startDate = startOfWeek(oneWeekAgo, { weekStartsOn: 1 });
    setDateTo(endDate);
    setDateFrom(startDate);
  };
  
  const setDateRangeToLastMonth = () => {
    const oneMonthAgo = sub(dateNow, { weeks: 4 });
    const startDate = startOfWeek(oneMonthAgo, { weekStartsOn: 1 });

    setDateTo(endDate);
    setDateFrom(startDate);
  };
  
  const setDateRangeToLastTwelveWeeks = () => {
    const twelveWeeksAgo = sub(dateNow, { weeks: 12 });
    const startDate = startOfWeek(twelveWeeksAgo, { weekStartsOn: 1 });

    setDateTo(endDate);
    setDateFrom(startDate);
  };
  
  const setDateRangeToLastSixMonths = () => {
    const sixMonthsAgo = sub(dateNow, { months: 6 });
    const startDate = startOfWeek(sixMonthsAgo, { weekStartsOn: 1 });

    setDateTo(endDate);
    setDateFrom(startDate);
  };
  
  const setDateRangeToLastYear = () => {
    const oneYearAgo = sub(dateNow, { months: 12 });
    const startDate = startOfWeek(oneYearAgo, { weekStartsOn: 1 });

    setDateTo(endDate);
    setDateFrom(startDate);
  };

  return (
    <Container maxWidth="xl">
      <form onSubmit={(e) => handleSubmit(e)} className="filter-form">
      <LocalizationProvider dateAdapter={DateAdapter}>
        <SectionCard>
          <Grid container spacing={2}>
          <Grid item xs={3}>
              <div className="date-pickers">
                <DatePicker
                  open={isOpenFrom}
                  onOpen={() => setIsOpenFrom(true)}
                  onClose={() => setIsOpenFrom(false)}
                  inputFormat="dd/MM/yyyy"
                  openTo="day"
                  label="Start - Week Commencing"
                  maxDate={startOfWeek(oneWeekAgo, { weekStartsOn: 1 })}
                  value={dateFrom}
                  disabled={loadingData}
                  onChange={(newValue) => {
                    handleOnChangeFrom(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="helper-text"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      onClick={(e) => setIsOpenFrom(true)}
                      {...params}
                      size="normal"
                    />
                  )}
                />
                <DatePicker
                  open={isOpenTo}
                  onOpen={() => setIsOpenTo(true)}
                  onClose={() => setIsOpenTo(false)}
                  inputFormat="dd/MM/yyyy"
                  openTo="day"
                  label="End - Week Ending"
                  minDate={dateFrom}
                  maxDate={ endOfWeek(oneWeekAgo, { weekStartsOn: 1 }) }
                  value={dateTo}
                  disabled={loadingData}
                  onChange={(newValue) => {
                    handleOnChangeTo(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      {...params}
                      onClick={(e) => setIsOpenTo(true)}
                      helperText={""}
                      size="normal"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={0.3}>
              <p className="or-select-text">Or</p>
            </Grid>

            <Grid item xs={3}>
              <Select
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                key={`my_unique_select_key__${selectedTimePeriod}`}
                value={selectedTimePeriod || "Select Time Period"}
                label="Select Time Period"
                options={optionsForPreselected}
                openMenuOnFocus={true}
                isMulti={false}
                isDisabled={loadingData}
                closeMenuOnSelect={true}
                placeholder={
                  selectedTimePeriod === null
                    ? "Select time period..."
                    : selectedTimePeriod
                }
                onChange={(timePeriod) => handleSelectChange(timePeriod)}
              ></Select>
            </Grid>
            <Grid item xs={3}>
              <Select
                options={groupsForSelect}
                defaultValue={selectedGroups}
                openMenuOnFocus={true}
                isMulti={true}
                isDisabled={loadingData}
                closeMenuOnSelect={false}
                placeholder="Select Group"
                onChange={(selection) => handleGroupChange(selection)}
              />
            </Grid>
            <Grid item xs={2}>
              <div className="text-left mt-2">
                <LoadingButton
                  disabled={disableBtn} 
                  className="apply-filters-btn"
                  loading={loadingData}
                  variant="contained"
                  type="submit"
                >
                  Apply filters
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </SectionCard>
      </LocalizationProvider>
      </form>
    </Container>
  );
}

export default FilterForm;
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import "./style.scss";

const CHART_OPTIONS_BAR = {
  maintainAspectRatio: false,
  categoryPercentage: 0.6,
  barPercentage: 0.7,
  animation: {
    duration: 1000,
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: 14,
          lineHeight: 1.6,
        },
        backdropPadding: 0,
        padding: 25,
        color: ['#666', '#666', '#666', '#666', "#666", "#666", "#666"],
      },

      align: "start",
      
      grid: {
        display: false,
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: "hours",
        color: "#666",
        align: "center",
        font: {
          size: 16,
          weight: "bold",
          lineHeight: 1.2,
        },
        padding: { top: 0, left: 0, right: 0, bottom: 5 },
      },

      ticks: {
        font: {
          size: 16,
        },
        beginAtZero: true,
        callback: (value) => {
          return hoursToDuration(value);
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 20,
        padding: 20,
      },
    },
    tooltip: {
      enabled: false,
      position: "average",
      mode: "index",
      intersect: false,
      callbacks: {
        label: (context) => {
          const tooltipDataLabel = context.dataset.label;
          return `${tooltipDataLabel}: ${hoursMinsToDuration(context.raw)}`;
        },
      },
      external: function (context) {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || [];
          const bodyLines = tooltip.body.map((b) => b.lines);

          const tableHead = document.createElement("p");
          tableHead.style.display = "flex";
          tableHead.style.fontSize = "16px";
          tableHead.style.fontWeight = "700";
          tableHead.style.margin = "5px 0";

          titleLines.forEach((title) => {
            const textArray = title.split("hrs");
            textArray.splice(0, 1);

            textArray.forEach((title, i) => {
              const textItem = document.createElement("span");
              textItem.style.width = "100%";

              const newTitle = title.replace(',', ' ');
              const headerText = document.createTextNode(newTitle);

              textItem.appendChild(headerText);
              tableHead.appendChild(textItem);
            });
          });

          const tableBody = document.createElement("div");
          tableBody.id = "tableBody";
          tableBody.style.width = "125px";
          tableBody.style.minHeight = "145px";

          bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const tr = document.createElement("div");
            tr.style.backgroundColor = "inherit";
            tr.style.borderWidth = 0;
            tr.style.width = "100%";
            tr.style.height = "45px";
            tr.style.padding = "2px 0";
            tr.style.position = "relative";

            const span = document.createElement("span");
            span.style.background = colors.backgroundColor;
            span.style.border = "white";
            span.style.borderWidth = "1px";
            span.style.borderStyle = "solid";
            span.style.height = "10px";
            span.style.width = "10px";
            span.style.display = "inline-block";
            span.style.position = "absolute";
            span.style.top = "25px";
            span.style.left = "1px";

            const bodyArray = body[0].split(":");
            bodyArray.forEach((body, i) => {
              const bodyItem = document.createElement("p");
              bodyItem.style.marginBottom = "0";
              bodyItem.style.whiteSpace = "nowrap";

              if (i == 1) {
                bodyItem.style.marginLeft = "20px";
              }

              const bodyText = document.createTextNode(body);

              bodyItem.appendChild(bodyText);
              tr.appendChild(bodyItem);
            });

            tr.appendChild(span);
            tableBody.appendChild(tr);
          });

          const tableFooter = document.createElement("div");
          tableFooter.style.display = "block";
          tableFooter.style.height = "auto";
          tableFooter.style.marginBottom = "5px";
          tableFooter.style.fontSize = "11px";
          tableFooter.innerHTML = "(Inc. Est. travel time)"

          const tableRoot = tooltipEl.querySelector("table");

          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }

          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
          tableRoot.appendChild(tableFooter);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + "px";
        tooltipEl.style.top = positionY + tooltip.caretY + "px";
        tooltipEl.style.padding = "0px 10px";
      },
    },
  },
};

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.display = "block";
    table.style.margin = "0px";
    table.style.padding = "0px";
    table.style.minWidth = "125px";
    table.style.maxWidth = "225px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const hoursToDuration = (hours) => {
  const hours2DP = hours.toFixed(5);
  const parts = hours2DP.split(".");
  const base = parseInt(parts[0]);
  const withComma = `${base}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${withComma}`;
};

const hoursMinsToDuration = (hours) => {
  const hours2DP = hours.toFixed(2);
  const parts = hours2DP.split(".");
  const base = parseInt(parts[0]);
  const mins = ((parts[1] / 100) * 60).toFixed(0);
  const formatTime = `${base}h  ${mins}m`;
  return `${formatTime}`;
};

const WeekViewBarChart = ({chartData, labels}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
  );
  
  const allocatedDemandData = chartData.datasetsDemand[0]; 
  const unallocatedDemandData = chartData.datasetsDemand[1];
  const capacityData = chartData.datasetsCapacity[0];

  const newLabels = labels.map((label, i) => {
    const diff = hoursMinsToDuration(capacityData.data[i] - (allocatedDemandData.data[i] + unallocatedDemandData.data[i]));
    let operator = " ";
    if ((capacityData.data[i] - (allocatedDemandData.data[i] + unallocatedDemandData.data[i])) > 0) {
      operator = "+";
    };
    if (hoursToDuration(capacityData.data[i] - (allocatedDemandData.data[i] + unallocatedDemandData.data[i])) == 0) {
      operator = "-";
    };
    if (hoursToDuration(capacityData.data[i] - (allocatedDemandData.data[i] + unallocatedDemandData.data[i])) <= 0) {
      CHART_OPTIONS_BAR.scales.x.ticks.color[i] = "#FAB005"
    };
    const formatLabel = label.replace('-', ' ');
    const arr = formatLabel.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const capLabel = arr.join(" ");

    label = [operator + diff, capLabel]; 
    return label;
  });

  const datasets = [
    {
      ...capacityData,
      stack: 'Stack 1',
    },
    {
      ...allocatedDemandData,
      stack: 'Stack 0',
    },
    {
      ...unallocatedDemandData,
      stack: 'Stack 0',
    },
  ]

  const data = {
    labels: newLabels,
    datasets: datasets
  }

  const plugins= [
    {
      id: `image`,
      afterDraw: (chart) => {
        const xAxis = chart.scales.x;
        const yAxis = chart.scales.y;
        xAxis.ticks.forEach((value, index) => {
          const x = xAxis.getPixelForTick(index);
          const image = new Image();
          if ((capacityData.data[index] - (allocatedDemandData.data[index] + unallocatedDemandData.data[index])) <= 0) {
            image.src = 'https://img.icons8.com/?size=25&id=8122&format=png&color=FAB005';
          }
          xAxis.ctx.drawImage(image, x - 18 , yAxis.left + 360)
        });
      },
    }
  ];

  return (
    <div className="chart-wrapper">
      <Bar
        options={CHART_OPTIONS_BAR}
        data={data}
        plugins={plugins}
      />
    </div>
  );
};

export default WeekViewBarChart;

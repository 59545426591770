import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";

export const Visits = ({ taxonomy, todaysVisits }) => {
  const titleTaxonomy = taxonomy.acuity_score;
  const clientTaxonomy = taxonomy.client;
  const visitTaxonomy = taxonomy.event;

  return (
  	<div className="module visits">
      <h4>Todays {visitTaxonomy}</h4>
          {todaysVisits && <> 
          <div className="stat-wrap">
            <div className="stat">
              <span>Individual {clientTaxonomy}</span>
              <p>{todaysVisits.individual_clients}</p>
            </div>
            <div className="stat">
              <span>Total {visitTaxonomy}</span>
              <p>{todaysVisits.total_events}</p>
            </div>
          </div>

          <ul className="acuity-list">
            <li>
              <span>{titleTaxonomy}</span>
              <span>{clientTaxonomy}</span>
              <span>{visitTaxonomy}</span>
            </li>
            {todaysVisits.data.map((value, index) => {
              const levelClass = (value.level).replace(/\s+/g, '-').toLowerCase();
              return <li key={index}>
                <div>
                  <span className={'acuity-' + levelClass} style={{ backgroundColor: value.colour }}></span>
                  <span>{value.level}</span>
                </div>
                <span>{value.clients}</span>
                <span>{value.events}</span>
              </li>
            })}
          </ul>
          </>}
      </div>
  );
};

export default Visits;
import { number } from "prop-types";
import React from "react";
import SectionCard from "../section_card/SectionCard";
import SectionDataGrid from "../section_data_grid/SectionDataGrid";
import { hoursComparator } from "../../../utils/utils.js";

import "./style.scss";

export const StaffBandsSection = ({ staffBands, loadingData }) => {
  const msToDuration = (params) => {
    const seconds = params.value;
    const hours2DP = (seconds / 60 / 60).toFixed(5);
    const parts = hours2DP.split(".");
    const base = parseInt(parts[0]);
    const decimal = parseFloat("0." + parts[1]);
    const minutes = Math.round(decimal * 60);
    const withComma = `${base}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${withComma}h ${minutes}m`;
  };

  const columns = [
    {
      field: "band",
      headerName: "Band",
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "capacity",
      headerName: "Capacity",
      flex: 1,
      sortComparator: hoursComparator,
      valueGetter: msToDuration,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "demand_plus_travel_time",
      headerName: "Demand",
      flex: 1,
      sortComparator: hoursComparator,

      valueGetter: msToDuration,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "dif",
      headerName: "Difference",
      flex: 1,
      sortComparator: hoursComparator,

      valueGetter: msToDuration,
      headerAlign: "right",
      align: "right",
    },
  ];

  let rows = [];

  if (staffBands) {
    rows = staffBands.data.map((entry, index) => {
      entry["id"] = index;
      return entry;
    });
  }

  return (
    <SectionCard title="Staff Bands" loadingData={loadingData}>
      {staffBands && (
        <div>
          <div className="section-totals">
            <h3>
              <span className="total-number">{staffBands.totalStaff}</span>{" "}
              Total Staff
            </h3>
          </div>
          <SectionDataGrid
            align={"right"}
            rows={rows}
            columns={columns}
            hideFooter={true}
          />
        </div>
      )}
    </SectionCard>
  );
};

export default StaffBandsSection;

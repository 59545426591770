import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import "./style.scss";

const CHART_OPTIONS_BAR = {
  maintainAspectRatio: false,
  categoryPercentage: 0.6,
  barPercentage: 0.7,
  animation: {
    duration: 1000,
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: 16
        },
      },

      align: "start",

      grid: {
        display: false,
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: "hours",
        color: "#666",
        align: "center",
        font: {
          size: 16,
          weight: "bold",
          lineHeight: 1.2,
        },
        padding: { top: 0, left: 0, right: 0, bottom: 5 },
      },

      ticks: {
        font: {
          size: 16,
        },
        beginAtZero: true,
        callback: (value) => {
          return hoursToDuration(value);
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 20,
        padding: 20,
      },
    },
    tooltip: {
      enabled: false,
      position: "average",
      mode: "index",
      intersect: false,
      callbacks: {
        label: (context) => {
          const tooltipDataLabel = context.dataset.label;
          return `${tooltipDataLabel}: ${hoursToDuration(context.raw)} hrs`;
        },
      },
      external: function (context) {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || [];
          const bodyLines = tooltip.body.map((b) => b.lines);

          const tableHead = document.createElement("p");
          tableHead.style.display = "flex";
          tableHead.style.fontSize = "16px";
          tableHead.style.fontWeight = "700";
          tableHead.style.margin = "5px 0";

          titleLines.forEach((title) => {
            const textArray = title.split(" ");
            textArray.splice(1, 1);

            textArray.forEach((title, i) => {
              const textItem = document.createElement("span");
              textItem.style.width = "50%";

              const headerText = document.createTextNode(title);

              textItem.appendChild(headerText);
              tableHead.appendChild(textItem);
            });
          });

          const tableBody = document.createElement("div");
          tableBody.id = "tableBody";
          tableBody.style.display = "flex";
          tableBody.style.flexWrap = "wrap";
          tableBody.style.flexDirection = "column";
          tableBody.style.maxHeight = "120px";

          if (bodyLines.length == 4) {
            tableBody.style.width = "225px";
          }

          bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const tr = document.createElement("div");
            tr.style.backgroundColor = "inherit";
            tr.style.borderWidth = 0;
            tr.style.flexBasis = "50%";
            tr.style.width = "auto";
            tr.style.height = "10px";
            tr.style.padding = "2px 0";
            tr.style.position = "relative";

            const span = document.createElement("span");
            span.style.background = colors.backgroundColor;
            span.style.border = "white";
            span.style.borderWidth = "1px";
            span.style.borderStyle = "solid";
            span.style.height = "10px";
            span.style.width = "10px";
            span.style.display = "inline-block";
            span.style.position = "absolute";
            span.style.top = "25px";
            span.style.left = "1px";

            const bodyArray = body[0].split(":");
            bodyArray.forEach((body, i) => {
              const bodyItem = document.createElement("p");
              bodyItem.style.marginBottom = "0";
              bodyItem.style.whiteSpace = "nowrap";

              if (i == 1) {
                bodyItem.style.marginLeft = "20px";
              }

              const bodyText = document.createTextNode(body);

              bodyItem.appendChild(bodyText);
              tr.appendChild(bodyItem);
            });

            tr.appendChild(span);
            tableBody.appendChild(tr);

            //console.log(tableBody);
          });

          const tableFooter = document.createElement("div");
          tableFooter.style.display = "block";
          tableFooter.style.height = "1px";

          const tableRoot = tooltipEl.querySelector("table");

          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }

          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
          tableRoot.appendChild(tableFooter);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + "px";
        tooltipEl.style.top = positionY + tooltip.caretY + "px";
        tooltipEl.style.padding = "0px 10px";
      },
    },
  },
};

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.display = "block";
    table.style.margin = "0px";
    table.style.padding = "0px";
    table.style.minWidth = "125px";
    table.style.maxWidth = "225px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const mergeTrendsDataForComparison = (trends, trendsCompare) => {
  return {
    labels: trends.labels.map(
      (label, index) =>
        `${label}` + " " + "/" + " " + `${trendsCompare.labels[index]}`
    ),
    datasets: [...trends.datasets, ...trendsCompare.datasets],
  };
};

const hoursToDuration = (hours) => {
  const hours2DP = hours.toFixed(5);
  const parts = hours2DP.split(".");
  const base = parseInt(parts[0]);
  const withComma = `${base}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${withComma}`;
};

const propsChanged = (prevProps, nextProps) => {
  const shouldNotRerender =
    prevProps.viewComparisonMode === nextProps.viewComparisonMode &&
    prevProps.trends === nextProps.trends &&
    prevProps.trendsCompare === nextProps.trendsCompare;

  return shouldNotRerender;
};

const TrendsBarChart = React.memo((props) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

  const chartData = props.viewComparisonMode
    ? mergeTrendsDataForComparison(props.trends, props.trendsCompare)
    : props.trends;

  return (
    <div className="trends-chart-wrapper">
      <Bar options={CHART_OPTIONS_BAR} data={chartData} />
    </div>
  );
}, propsChanged);

export default TrendsBarChart;
